import {
    IModelingDiagram, IModelingDiagramElementView,
    IModelingDiagramItemView,
    IModelingProject
} from "../abstractions";
import {IModelingDiagramItemViewData} from "../persistence";
import {IPropertySubject, PropertyMappedSubject, PropertySubject} from "../../lib/rxjs-ex";

export class ModelingDiagramItemView implements IModelingDiagramItemView {
    public readonly project: IModelingProject;
    public readonly id: string;

    public readonly parentViewId$ = new PropertySubject<string | undefined>(undefined);
    public readonly parentView$ : IPropertySubject<IModelingDiagramElementView | undefined>;

    constructor(public readonly diagram: IModelingDiagram, data: IModelingDiagramItemViewData) {
        this.id = data.id ?? crypto.randomUUID();
        this.project = diagram.project;
        this.parentView$ = new PropertyMappedSubject<string | undefined, IModelingDiagramElementView | undefined>(this.parentViewId$,
            (id) => id ? this.diagram.getOrCreateElementView({id: id}) : undefined,
            (parentView) => parentView?.id);
    }

    readFromData(data: IModelingDiagramItemViewData) {
    }

    writeToData(data: IModelingDiagramItemViewData) {
    }
}
