import ReactFlow, {
    Node,
    Edge,
    MarkerType,
    Background,
    Controls,
    ReactFlowInstance,
    NodeChange,
    NodePositionChange,
    useNodesState, useEdgesState
} from "reactflow";

import {ArchimateElementView} from "./ArchimateElementView";
import {ArchimateEdgeView} from "./ArchimateEdgeView";
import CustomConnectionLine from "./CustomConnectionLine";

import "reactflow/dist/style.css";
import "../Archimate.css";

import {
    IModelingDiagram,
    IModelingDiagramElementView,
    IModelingDiagramRelationshipView
} from "../../../modeling/abstractions";
import {bind} from "../../../lib/react-rxjs-ex";
import {IModelingDiagramElementViewData} from "../../../modeling/persistence";
import React, {useCallback, useEffect, useState} from "react";
import {useModelingDiagramContext} from "../../../modeling/components/ModelingDiagramProvider";

const connectionLineStyle = {
    strokeWidth: 3,
    stroke: 'black',
};

const nodeTypes = {
    archiMateNode: ArchimateElementView
};

const edgeTypes = {
    floating: ArchimateEdgeView,
};

const defaultEdgeOptions = {
    style: { strokeWidth: 3, stroke: 'black' },
    type: 'floating',
    markerEnd: {
        type: MarkerType.ArrowClosed,
        color: 'black',
    }
}

function mapDiagramNode(elementView: IModelingDiagramElementView): Node<IModelingDiagramElementView> {
    let position = {
        x: elementView.x$.value,
        y: elementView.y$.value
    };

    let parentView = elementView.parentView$.value;
    while (parentView) {
        position.x += parentView.x$.value;
        position.y += parentView.y$.value;
        parentView = parentView.parentView$.value;
    }

    return {
        id: elementView.id,
        type: "archiMateNode",
        data: elementView,
        position: position,
        width: elementView.width$.value,
        height: elementView.height$.value
    };
}

function mapDiagramEdge(relationshipView: IModelingDiagramRelationshipView): Edge {
    return {
        id: relationshipView.id,
        type: "floating",
        data: relationshipView,
        source: relationshipView.sourceViewId$.value ?? "",
        sourceHandle: relationshipView.sourceViewId$.value ?? "",
        target: relationshipView.targetViewId$.value ?? "",
        targetHandle: relationshipView.targetViewId$.value ?? ""
    };
}

const [useDiagramElementViews] = bind((diagram: IModelingDiagram) => diagram.elementViews$);
const [useDiagramRelationshipViews] = bind((diagram: IModelingDiagram) => diagram.relationshipViews$);

const FlowDiagram = () => {
    const {diagram} = useModelingDiagramContext();

    const elementViews = useDiagramElementViews(diagram);
    const initialNodes = Object.values(elementViews).map(mapDiagramNode);
    const [nodes, setNodes, flowNodesChange] = useNodesState(initialNodes);

    const relationshipViews = useDiagramRelationshipViews(diagram);
    const initialEdges = Object.values(relationshipViews).map(mapDiagramEdge);
    const [edges, setEdges, flowEdgesChange] = useEdgesState(initialEdges);

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | undefined>(undefined);

    const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const types = event.dataTransfer.types;
        if (!types || (types.indexOf('application/elements') < 0)) {
            event.dataTransfer.dropEffect = 'none';
            return;
        }

        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const data = event.dataTransfer.getData('application/elements');
        if (!data)
            return;

        const position = reactFlowInstance?.screenToFlowPosition({
            x: event.clientX - 60,
            y: event.clientY - 30
        }) ?? {
            x: event.clientX - 60,
            y: event.clientY - 30
        };

        const elementViewData = JSON.parse(data) as IModelingDiagramElementViewData;
        elementViewData.x = position.x;
        elementViewData.y = position.y;
        elementViewData.width = 120;
        elementViewData.height = 60;
        console.debug("Dropping", elementViewData);
        diagram.getOrCreateElementView(elementViewData);

    }, [diagram, reactFlowInstance]);

    useEffect(() => {
        setNodes(Object.values(elementViews).map(mapDiagramNode));
    }, [elementViews, setNodes]);

    const nodesChange = useCallback((nodeChanges: NodeChange[]) => {
        console.debug("Node changes", nodeChanges);
        for (const nodeChange of nodeChanges) {
            if (nodeChange.type === 'position') {
                const nodePositionChange = nodeChange as NodePositionChange;
                if (!nodePositionChange.position)
                    continue;
                console.debug("Node position change", nodePositionChange);

                const elementView = diagram.elementViews$.get(nodePositionChange.id);
                if (!elementView)
                    continue;
                elementView.x$.next(nodePositionChange.position.x);
                elementView.y$.next(nodePositionChange.position.y);
            }
        }

        flowNodesChange(nodeChanges);
    }, [diagram, flowNodesChange]);

    return (
        <ReactFlow
            onInit={setReactFlowInstance}
            onDragOver={onDragOver}
            onDrop={onDrop}
            zoomOnScroll={true}

            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={nodesChange}

            edgeTypes={edgeTypes}
            defaultEdgeOptions={defaultEdgeOptions}
            edges={edges}
            onEdgesChange={flowEdgesChange}

            connectionLineComponent={CustomConnectionLine}
            connectionLineStyle={connectionLineStyle}>
            <Background/>
            <Controls/>
        </ReactFlow>
    );
};

export default FlowDiagram;
