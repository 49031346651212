import {IWorkspaceItem} from "../../workspace/abstractions";
import {IWorkspaceItemData} from "../../workspace/persistence";
import {IModelingProjectContentData} from "../persistence";

export class ModellingLocalStorage {
    private static GetProjectContentItemKey(id: string) {
        return `project_${id}_content`;
    }

    public static ReadProjectContent(id: string) {
        const dataString = localStorage.getItem(this.GetProjectContentItemKey(id));
        if (!dataString)
            return null;
        return JSON.parse(dataString) as IModelingProjectContentData;
    }

    public static WriteProjectContent(id: string, data: IModelingProjectContentData) {
        localStorage.setItem(this.GetProjectContentItemKey(id), JSON.stringify(data));
    }

    private static GetProjectSummaryItemKey(id: string) {
        return `project_${id}`;
    }

    public static ReadProjectSummaryData(id: string) {
        const summaryString = localStorage.getItem(this.GetProjectSummaryItemKey(id));
        if (!summaryString)
            return;
        return JSON.parse(summaryString) as IWorkspaceItemData;
    }

    public static WriteProjectSummaryData(summary: IWorkspaceItem) {
        const summaryData: IWorkspaceItemData = {
            id: summary.Id,
            name: summary.Name$.value,
            createDateTime: summary.CreateDateTime.valueOf(),
            updateDateTime: summary.UpdateDateTime$.value.valueOf(),
            accessDateTime: summary.AccessDateTime$.value.valueOf()
        };
        localStorage.setItem(this.GetProjectSummaryItemKey(summary.Id), JSON.stringify(summaryData));
    }

    private static GetProjectIdsItemKey() {
        return "projectids";
    }

    public static ReadProjectIds(): string[] {
        const ids_string = localStorage.getItem(this.GetProjectIdsItemKey());
        return (ids_string ? JSON.parse(ids_string) : []) as string[];
    }

    public static WriteProjectIds(ids: string[]): void {
        localStorage.setItem(this.GetProjectIdsItemKey(), JSON.stringify(ids));
    }
}