import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {useWorkspace} from "./WorkspaceProvider";
import {Card, CardActionArea} from "@mui/material";

export default function WorkspaceImportDropzone() {

    const workspace = useWorkspace();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        workspace.tryImportProject(acceptedFiles);
    }, [workspace]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <Card key="dzcard" sx={{maxWidth: 'lg'}}>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
        </Card>
    );
}