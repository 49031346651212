import {ICollectionSubject} from "./ICollectionSubject";
import {Observable, Observer, Operator, Subscriber, Subscription} from "rxjs";
import {map} from "rxjs/operators";

export class CollectionMappedSubject<TSource, T> implements ICollectionSubject<T> {
    private readonly _forward: (x: TSource[]) => T[];
    private readonly _backward: (x: T[]) => TSource[];
    public operator: Operator<any, T[]> | undefined;
    public source: Observable<any> | undefined;

    public forEach(next: (value: T[]) => void): Promise<void> {
        return this._inner.forEach(x => next(this._forward(x)));
    }

    public lift<R>(operator: Operator<T[], R> | undefined): Observable<R> {
        if (operator === undefined) return this._inner.lift(undefined);

        const forward = this._forward;

        return this._inner.lift<R>({
            call(subscriber: Subscriber<R>, source: any): any {
                const sourceT = forward(source);
                return operator.call(subscriber, sourceT);
            }
        });
    }

    public pipe(): Observable<T[]> {
        return this._inner.pipe(map(this._forward));
    }

    public subscribe(observerOrNext?: Partial<Observer<T[]>> | ((value: T[]) => void)): Subscription;
    public subscribe(next?: ((value: T[]) => void) | null, error?: ((error: any) => void) | null, complete?: (() => void) | null): Subscription;

    public subscribe(observerOrNext?: Partial<Observer<T[]>> | ((value: T[]) => void) | null, error?: ((error: any) => void) | null, complete?: (() => void) | null): Subscription {
        if (observerOrNext && typeof observerOrNext === "object") {
            return this._inner.subscribe({
                next: x => observerOrNext.next?.(this._forward(x)),
                error: observerOrNext.error,
                complete: observerOrNext.complete
            });
        } else {
            return this._inner.subscribe(
                x => observerOrNext?.(this._forward(x)),
                error,
                complete
            );
        }
    }

    public async toPromise(): Promise<T[] | undefined> {
        const resultSource = await this._inner.toPromise();
        if (resultSource === undefined) return undefined;
        return this._forward(resultSource);
    }

    public add(...values: T[]): boolean {
        return this._inner.add(...this._backward(values));
    }

    public remove(...values: T[]): boolean {
        return this._inner.remove(...this._backward(values));
    }

    public get value(): T[] {
        return this._forward(this._inner.value);
    }

    public next(value: T[]): boolean {
        const newValue = this._backward(value);
        return this._inner.next(newValue);
    }

    public change(handler?: { (oldValue: T[], newValue: T[]): void } | undefined): void {
        this._inner.change((oldValue, newValue) => handler?.(this._forward(oldValue), this._forward(newValue)));
    }

    public constructor(private readonly _inner: ICollectionSubject<TSource>,
                       forward: (x: TSource) => T,
                       backward: (x: T) => TSource) {
        this._forward = (x: TSource[]) => x.map(forward);
        this._backward = (x: T[]) => x.map(backward);
    }
}
