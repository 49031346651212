import {IArchimateItemParseLogic} from "./IArchimateItemParseLogic";
import {ImportContext} from "../../../import/services/ImportContext";
import {Xml} from "../../../lib/util";
import {IModelingDiagramData, IModelingDiagramRelationshipViewData} from "../../../modeling/persistence";
import {hrefToId} from "./HrefToId";

interface IDiagramViewReference {
    href: string;
}

interface IDiagramViewSourceConnection {
    id: string;
    source?: string;
    target?: string;
    archimateRelationship: IDiagramViewReference[];
}

interface IDiagramViewBounds {
    x: string;
    y: string;
    width: string;
    height: string;
}

interface IDiagramElementView {
    id: string;

    targetConnections?: string;
    sourceConnections?: IDiagramViewSourceConnection[];

    bounds?: IDiagramViewBounds[];

    children?: IDiagramElementView[];
    archimateElement?: IDiagramViewReference[];
}

interface IDiagramEntity {
    id: string;
    name: string;
    type?: string;
    children?: IDiagramElementView[];
}


class DiagramParseLogic implements IArchimateItemParseLogic {
    xmlElementNames: string[] = ["ArchimateDiagramModel"];

    async importXmlResource(context: ImportContext, path: string, element: Element) {
        const entity = Xml.createFromElement(element) as IDiagramEntity;
        const resource = context.getOrCreateResource(path, entity.id);
        resource.diagramData = {
            ...resource.diagramData,
            id: entity.id,
            name: entity.name,
            stereotypes: {
                ...resource.diagramData?.stereotypes,
                archimate: ["diagram"]
            }
        };

        this.parseElementViews(context, resource.diagramData, entity.children);
    }

    private parseElementViews(context: ImportContext, diagram: IModelingDiagramData, views?: IDiagramElementView[], parentViewId?: string) {
        if (!views) return;

        for (const view of views)
            this.parseElementView(context, diagram, view, parentViewId);
    }

    private parseElementView(context: ImportContext, diagram: IModelingDiagramData, view: IDiagramElementView, parentViewId?: string) {
        const elementId = hrefToId(view.archimateElement?.[0]?.href);
        if (!elementId) return;

        if (!diagram.elementViews)
            diagram.elementViews = {};

        const elementView = diagram.elementViews[view.id] ?? (diagram.elementViews[view.id] = {
            id: view.id,
            elementId: elementId,
        });

        if (parentViewId)
            elementView.parentViewId = parentViewId;

        const bounds = view.bounds?.[0];
        if (bounds) {
            elementView.x = Number(bounds.x);
            elementView.y = Number(bounds.y);
            elementView.width = Number(bounds.width);
            elementView.height = Number(bounds.height);
        }

        if (elementView.x === undefined || elementView.y === undefined || elementView.width === undefined || elementView.height === undefined)
            context.warn(elementId, `Diagram ${diagram.id} has an element view ${elementView.id} with no bounds`);

        this.parseRelations(diagram, view.sourceConnections);

        this.parseElementViews(context, diagram, view.children, view.id);
    }

    private parseRelations(diagram: IModelingDiagramData, relations: IDiagramViewSourceConnection[] | undefined) {
        if (!relations) return;
        for (const relation of relations) {
            this.parseRelation(diagram, relation);
        }
    }

    private parseRelation(diagram: IModelingDiagramData, relation: IDiagramViewSourceConnection) {
        const relationshipId = hrefToId(relation.archimateRelationship?.[0]?.href);
        if (!relationshipId) return;

        if (!diagram.relationshipViews)
            diagram.relationshipViews = {};

        const relationshipView: IModelingDiagramRelationshipViewData
            = diagram.relationshipViews[relation.id]
            ?? (diagram.relationshipViews[relation.id] = {
                id: relation.id,
                relationshipId: relationshipId,
            });

        if (relation.source)
            relationshipView.sourceViewId = hrefToId(relation.source);

        if (relation.target)
            relationshipView.targetViewId = hrefToId(relation.target);
    }
}

export const diagramParseLogic = new DiagramParseLogic();
