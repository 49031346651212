import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ActorIcon from '../../archimate/img/elements/ActorIcon.svg';
import ArtifactIcon from '../../archimate/img/elements/ArtifactIcon.svg';
import AssessmentIcon from '../../archimate/img/elements/AssessmentIcon.svg';
import CapabilityIcon from '../../archimate/img/elements/CapabilityIcon.svg';
import CollaborationIcon from '../../archimate/img/elements/CollaborationIcon.svg';
import CommunicationNetworkIcon from '../../archimate/img/elements/CommunicationNetworkIcon.svg';
import ComponentIcon from '../../archimate/img/elements/ComponentIcon.svg';
import ConstraintIcon from '../../archimate/img/elements/ConstraintIcon.svg';
import ContractIcon from '../../archimate/img/elements/ContractIcon.svg';
import CourseOfActionIcon from '../../archimate/img/elements/CourseOfActionIcon.svg';
import DeliverableIcon from '../../archimate/img/elements/DeliverableIcon.svg';
import DeviceIcon from '../../archimate/img/elements/DeviceIcon.svg';
import DistributionNetworkIcon from '../../archimate/img/elements/DistributionNetworkIcon.svg';
import DriverIcon from '../../archimate/img/elements/DriverIcon.svg';
import EquipmentIcon from '../../archimate/img/elements/EquipmentIcon.svg';
import EventIcon from '../../archimate/img/elements/EventIcon.svg';
import FacilityIcon from '../../archimate/img/elements/FacilityIcon.svg';
import FunctionIcon from '../../archimate/img/elements/FunctionIcon.svg';
import GapIcon from '../../archimate/img/elements/GapIcon.svg';
import GoalIcon from '../../archimate/img/elements/GoalIcon.svg';
import GroupIcon from '../../archimate/img/elements/GroupIcon.svg';
import InteractionIcon from '../../archimate/img/elements/InteractionIcon.svg';
import InterfaceIcon from '../../archimate/img/elements/InterfaceIcon.svg';
import JunctionIcon from '../../archimate/img/elements/JunctionIcon.svg';
import LocationIcon from '../../archimate/img/elements/LocationIcon.svg';
import MaterialIcon from '../../archimate/img/elements/MaterialIcon.svg';
import MeaningIcon from '../../archimate/img/elements/MeaningIcon.svg';
import NodeIcon from '../../archimate/img/elements/NodeIcon.svg';
import ObjectIcon from '../../archimate/img/elements/ObjectIcon.svg';
import OutcomeIcon from '../../archimate/img/elements/OutcomeIcon.svg';
import PathIcon from '../../archimate/img/elements/PathIcon.svg';
import PlateauIcon from '../../archimate/img/elements/PlateauIcon.svg';
import PrincipleIcon from '../../archimate/img/elements/PrincipleIcon.svg';
import ProcessIcon from '../../archimate/img/elements/ProcessIcon.svg';
import ProductIcon from '../../archimate/img/elements/ProductIcon.svg';
import RepresentationIcon from '../../archimate/img/elements/RepresentationIcon.svg';
import RequirementIcon from '../../archimate/img/elements/RequirementIcon.svg';
import ResourceIcon from '../../archimate/img/elements/ResourceIcon.svg';
import RoleIcon from '../../archimate/img/elements/RoleIcon.svg';
import ServiceIcon from '../../archimate/img/elements/ServiceIcon.svg';
import SystemSoftwareIcon from '../../archimate/img/elements/SystemSoftwareIcon.svg';
import StakeholderIcon from '../../archimate/img/elements/StakeholderIcon.svg';
import ValueIcon from '../../archimate/img/elements/ValueIcon.svg';
import ValueStreamIcon from '../../archimate/img/elements/ValueStreamIcon.svg';
import WorkPackageIcon from '../../archimate/img/elements/WorkPackageIcon.svg';
import {Tooltip} from "@mui/material";

const layers = [
    {
        layer: 'motivation',
        color: '#ccccff',
        elements: [
            {
                element: {
                    name: 'Stakeholder',
                    stereotypes: {archimate: ["element", "motivation", "stakeholder"]},
                },
                icon: StakeholderIcon
            }, {
                element: {
                    name: 'Driver',
                    stereotypes: {archimate: ["element", "motivation", "driver"]},
                },
                icon: DriverIcon
            }, {
                element: {
                    name: 'Assessment',
                    stereotypes: {archimate: ["element", "motivation", "assessment"]},
                },
                icon: AssessmentIcon
            }, {
                element: {
                    name: 'Goal',
                    stereotypes: {archimate: ["element", "motivation", "goal"]},
                },
                icon: GoalIcon
            }, {
                element: {
                    name: 'Outcome',
                    stereotypes: {archimate: ["element", "motivation", "outcome"]},
                },
                icon: OutcomeIcon
            }, {
                element: {
                    name: 'Principle',
                    stereotypes: {archimate: ["element", "motivation", "principle"]},
                },
                icon: PrincipleIcon
            }, {
                element: {
                    name: 'Requirement',
                    stereotypes: {archimate: ["element", "motivation", "requirement"]},
                },
                icon: RequirementIcon
            }, {
                element: {
                    name: 'Constraint',
                    stereotypes: {archimate: ["element", "motivation", "constraint"]},
                },
                icon: ConstraintIcon
            }, {
                element: {
                    name: 'Meaning',
                    stereotypes: {archimate: ["element", "motivation", "meaning"]},
                },
                icon: MeaningIcon
            }, {
                element: {
                    name: 'Value',
                    stereotypes: {archimate: ["element", "motivation", "value"]},
                },
                icon: ValueIcon
            }]
    }, {
        layer: 'strategy',
        color: '#f5deaa',
        elements: [
            {
                element: {
                    name: 'Resource',
                    stereotypes: {archimate: ["element", "strategy", "resource"]},
                },
                icon: ResourceIcon
            }, {
                element: {
                    name: 'Capability',
                    stereotypes: {archimate: ["element", "strategy", "capability"]},
                },
                icon: CapabilityIcon
            }, {
                element: {
                    name: 'Value Stream',
                    stereotypes: {archimate: ["element", "strategy", "value stream"]},
                },
                icon: ValueStreamIcon
            }, {
                element: {
                    name: 'Course of Action',
                    stereotypes: {archimate: ["element", "strategy", "course of action"]},
                },
                icon: CourseOfActionIcon
            }
        ]
    }, {
        layer: 'business',
        color: '#ffffaf',
        elements: [
            {
                element: {
                    name: 'Business actor',
                    stereotypes: {archimate: ["element", "business", "active structure", "actor"]},
                },
                icon: ActorIcon
            }, {
                element: {
                    name: 'Business role',
                    stereotypes: {archimate: ["element", "business", "active structure", "role"]},
                },
                icon: RoleIcon
            }, {
                element: {
                    name: 'Business collaboration',
                    stereotypes: {archimate: ["element", "business", "active structure", "collaboration"]},
                },
                icon: CollaborationIcon
            }, {
                element: {
                    name: 'Business interface',
                    stereotypes: {archimate: ["element", "business", "active structure", "interface"]},
                },
                icon: InterfaceIcon
            }, {
                element: {
                    name: 'Business process',
                    stereotypes: {archimate: ["element", "business", "behavior", "process"]},
                },
                icon: ProcessIcon
            }, {
                element: {
                    name: 'Business function',
                    stereotypes: {archimate: ["element", "business", "behavior", "function"]},
                },
                icon: FunctionIcon
            }, {
                element: {
                    name: 'Business interaction',
                    stereotypes: {archimate: ["element", "business", "behavior", "interaction"]},
                },
                icon: InteractionIcon
            }, {
                element: {
                    name: 'Business event',
                    stereotypes: {archimate: ["element", "business", "behavior", "event"]},
                },
                icon: EventIcon
            }, {
                element: {
                    name: 'Business service',
                    stereotypes: {archimate: ["element", "business", "behavior", "service"]},
                },
                icon: ServiceIcon
            }, {
                element: {
                    name: 'Business object',
                    stereotypes: {archimate: ["element", "business", "passive structure", "object"]},
                },
                icon: ObjectIcon
            }, {
                element: {
                    name: 'Contract',
                    stereotypes: {archimate: ["element", "business", "passive structure", "contract"]},
                },
                icon: ContractIcon
            }, {
                element: {
                    name: 'Representation',
                    stereotypes: {archimate: ["element", "business", "passive structure", "representation"]},
                },
                icon: RepresentationIcon
            }, {
                element: {
                    name: 'Product',
                    stereotypes: {archimate: ["element", "business", "composite", "product"]},
                },
                icon: ProductIcon
            }
        ]
    }, {
        layer: 'application',
        color: '#afffff',
        elements: [
            {
                element: {
                    name: 'Application component',
                    stereotypes: {archimate: ["element", "application", "active structure", "component"]},
                },
                icon: ComponentIcon
            }, {
                element: {
                    name: 'Application collaboration',
                    stereotypes: {archimate: ["element", "application", "active structure", "collaboration"]},
                },
                icon: CollaborationIcon
            }, {
                element: {
                    name: 'Application interface',
                    stereotypes: {archimate: ["element", "application", "active structure", "interface"]},
                },
                icon: InterfaceIcon
            }, {
                element: {
                    name: 'Application function',
                    stereotypes: {archimate: ["element", "application", "behavior", "function"]},
                },
                icon: FunctionIcon
            }, {
                element: {
                    name: 'Application interaction',
                    stereotypes: {archimate: ["element", "application", "behavior", "interaction"]},
                },
                icon: InteractionIcon
            }, {
                element: {
                    name: 'Application process',
                    stereotypes: {archimate: ["element", "application", "behavior", "process"]},
                },
                icon: ProcessIcon
            }, {
                element: {
                    name: 'Application event',
                    stereotypes: {archimate: ["element", "application", "behavior", "event"]},
                },
                icon: EventIcon
            }, {
                element: {
                    name: 'Application service',
                    stereotypes: {archimate: ["element", "application", "behavior", "service"]},
                },
                icon: ServiceIcon
            }, {
                element: {
                    name: 'Data object',
                    stereotypes: {archimate: ["element", "application", "passive structure", "object"]},
                },
                icon: ObjectIcon
            }]
    }, {
        layer: 'technology',
        color: '#afffaf',
        elements: [
            {
                element: {
                    name: 'Node',
                    stereotypes: {archimate: ["element", "technology", "active structure", "node"]},
                },
                icon: NodeIcon
            }, {
                element: {
                    name: 'Device',
                    stereotypes: {archimate: ["element", "technology", "active structure", "device"]},
                },
                icon: DeviceIcon
            }, {
                element: {
                    name: 'System software',
                    stereotypes: {archimate: ["element", "technology", "active structure", "system software"]},
                },
                icon: SystemSoftwareIcon
            }, {
                element: {
                    name: 'Technology collaboration',
                    stereotypes: {archimate: ["element", "technology", "active structure", "collaboration"]},
                },
                icon: CollaborationIcon
            }, {
                element: {
                    name: 'Technology interface',
                    stereotypes: {archimate: ["element", "technology", "active structure", "interface"]},
                },
                icon: InterfaceIcon
            }, {
                element: {
                    name: 'Path',
                    stereotypes: {archimate: ["element", "technology", "active structure", "path"]},
                },
                icon: PathIcon
            }, {
                element: {
                    name: 'Communication network',
                    stereotypes: {archimate: ["element", "technology", "active structure", "network"]},
                },
                icon: CommunicationNetworkIcon
            }, {
                element: {
                    name: 'Technology function',
                    stereotypes: {archimate: ["element", "technology", "behavior", "function"]},
                },
                icon: FunctionIcon
            }, {
                element: {
                    name: 'Technology process',
                    stereotypes: {archimate: ["element", "technology", "behavior", "process"]},
                },
                icon: ProcessIcon
            }, {
                element: {
                    name: 'Technology interaction',
                    stereotypes: {archimate: ["element", "technology", "behavior", "interaction"]},
                },
                icon: InteractionIcon
            }, {
                element: {
                    name: 'Technology event',
                    stereotypes: {archimate: ["element", "technology", "behavior", "event"]},
                },
                icon: EventIcon
            }, {
                element: {
                    name: 'Technology service',
                    stereotypes: {archimate: ["element", "technology", "behavior", "service"]},
                },
                icon: ServiceIcon
            }, {
                element: {
                    name: 'Artifact',
                    stereotypes: {archimate: ["element", "technology", "passive structure", "artifact"]},
                },
                icon: ArtifactIcon
            }, {
                element: {
                    name: 'Equipment',
                    stereotypes: {archimate: ["element", "technology", "active structure", "physical", "equipment"]},
                },
                icon: EquipmentIcon
            }, {
                element: {
                    name: 'Facility',
                    stereotypes: {archimate: ["element", "technology", "active structure", "physical", "facility"]},
                },
                icon: FacilityIcon
            }, {
                element: {
                    name: 'Distribution network',
                    stereotypes: {archimate: ["element", "technology", "active structure", "physical", "distribution network"]},
                },
                icon: DistributionNetworkIcon
            }, {
                element: {
                    name: 'Material',
                    stereotypes: {archimate: ["element", "technology", "passive structure", "material"]},
                },
                icon: MaterialIcon
            }]
    }, {
        layer: 'implementation and migration',
        color: '#ffe0e0',
        elements: [
            {
                element: {
                    name: 'Work package',
                    stereotypes: {archimate: ["element", "implementation and migration", "work package"]},
                },
                icon: WorkPackageIcon
            }, {
                element: {
                    name: 'Deliverable',
                    stereotypes: {archimate: ["element", "implementation and migration", "deliverable"]},
                },
                icon: DeliverableIcon
            }, {
                element: {
                    name: 'Implementation event',
                    stereotypes: {archimate: ["element", "implementation and migration", "event"]},
                },
                icon: EventIcon
            }, {
                element: {
                    name: 'Plateau',
                    stereotypes: {archimate: ["element", "implementation and migration", "plateau"]},
                },
                icon: PlateauIcon
            }, {
                element: {
                    name: 'Gap',
                    stereotypes: {archimate: ["element", "implementation and migration", "gap"]},
                },
                icon: GapIcon
            }]
    }, {
        layer: 'shared',
        color: '#eacde0',
        elements: [
            {
                element: {
                    name: 'Location',
                    stereotypes: {archimate: ["element", "shared", "composite", "location"]},
                },
                icon: LocationIcon
            }, {
                element: {
                    name: 'Junction',
                    stereotypes: {archimate: ["junction"]},
                },
                icon: JunctionIcon
            }, {
                element: {
                    name: 'Grouping',
                    stereotypes: {archimate: ["element", "shared", "composite", "grouping"]},
                },
                icon: GroupIcon
            }
        ]
    }
];

export default function ProjectElementBoxPartial() {
    return <Box sx={{
        minHeight: '100%',
        maxHeight: '100%',
        width: '175px',
        minWidth: '175px',
        maxWidth: '175px',
        flexGrow: 0,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    }}>
        {layers.map(({layer, color, elements}) =>
            <Box key={layer} sx={{
                backgroundColor: color,
                wordWrap: 'break-word',
                textAlign: 'left'
            }}>
                <Box style={{
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.25,
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    padding: '0.5rem 1rem',
                }}>{layer}</Box>
                {elements.map(({element, icon}) =>
                    <Tooltip title={element.name} key={layer + element}>
                        <IconButton sx={{
                            height: '35px',
                            width: '35px'
                        }}
                                    key={layer + element}
                                    draggable={true}
                                    onDragStart={(ea) => {
                                        ea.dataTransfer.setData('application/elements', JSON.stringify({
                                            element: element
                                        }));
                                        console.debug(ea.dataTransfer.getData('application/elements'));
                                    }}>
                            <img style={{
                                height: '20px',
                                width: '20px'
                            }} src={icon} alt={element.name}/>
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        )}
    </Box>
}