import {BehaviorSubject} from "rxjs";
import {IPropertySubject} from "./IPropertySubject";

export class PropertySubject<T> extends BehaviorSubject<T> implements IPropertySubject<T> {
    private _changeHandler: { (oldValue: T, newValue: T): void } | undefined;

    public override next(value: T): boolean {
        const oldValue = this.value;
        if (oldValue === value) return false;

        super.next(value);
        if (this._changeHandler) this._changeHandler(oldValue, value);
        return true;
    }

    public change(handler?: { (oldValue: T, newValue: T): void } | undefined): void {
        this._changeHandler = handler;
    }
}
