import {ArchimateElement} from "../../../_generated/aiaea/v1";

interface ElementPredictorData{
    element: ArchimateElement,
    onDragStart: (event: React.DragEvent<HTMLDivElement>) => void
}

const ArchimateElementView = ({element, onDragStart}: ElementPredictorData) => {
    let stereotypes = element.stereotypes?.["archimate"] ?? [];
    stereotypes = [...stereotypes, "icon"];

    const className = stereotypes.join(" ") ?? "";

    return (
        <div className="archimatenode" draggable={true} onDragStart={onDragStart} style={{
            marginTop: "1em",
            marginLeft: "1em",
            marginRight: "1em"
        }}>
            <div className={className}>
                <div className="frame">
                    <div className="content">
                        {element.name}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArchimateElementView;
