import {RouteObject} from "react-router-dom";
import ProjectListPage from "./WorkspacePage";
import ProjectViewPage from "../../modeling/components/ModelingProjectViewPage";

export function getWorkspaceRoutes(): RouteObject[] {
    return [
        {
            path: 'projects',
            element: <ProjectListPage/>
        },
        {
            path: 'projects/:projectId',
            element: <ProjectViewPage/>
        },
    ];
}

export function getWorkspaceMenuItems(): { key: string, name: string, path?: string }[] {
    return [
        {key: "projects", name: 'Projects', path: '/projects'},
    ];
}
