import {
    IModelingDiagram,
    IModelingDiagramElementView,
    IModelingElement
} from "../abstractions";
import {ModelingDiagramItemView} from "./ModelingDiagramItemView";
import {
    IPropertySubject,
    PropertyMappedSubject,
    PropertySubject
} from "../../lib/rxjs-ex";
import {IModelingDiagramElementViewData} from "../persistence";

export class ModelingDiagramElementView extends ModelingDiagramItemView implements IModelingDiagramElementView {
    public readonly elementId: string;

    public get element(): IModelingElement {
        return this.project.getOrCreateElement({
            id: this.elementId,
            parentId: this.diagram.parentId$.value,
        });
    }

    public readonly x$: IPropertySubject<number>;
    public readonly y$: IPropertySubject<number>;
    public readonly width$: IPropertySubject<number>;
    public readonly height$: IPropertySubject<number>;

    public readFromData(data: IModelingDiagramElementViewData): void {
        super.readFromData(data);

        this.parentViewId$.next(data.parentViewId);
        this.x$.next(data.x ?? 0);
        this.y$.next(data.y ?? 0);
        this.width$.next(data.width ?? 120);
        this.height$.next(data.height ?? 60);
    }

    public writeToData(data: IModelingDiagramElementViewData): void {
        super.writeToData(data);

        data.elementId = this.elementId;

        if (this.parentViewId$.value)
            data.parentViewId = this.parentViewId$.value;

        data.x = this.x$.value;
        data.y = this.y$.value;
        data.width = this.width$.value;
        data.height = this.height$.value;
    }

    constructor(diagram: IModelingDiagram, data: IModelingDiagramElementViewData) {
        if (!data.elementId) {
            if (!data.element)
                throw new Error("Spec must have an element or element ID.");
            const element = diagram.project.getOrCreateElement(data.element);
            data.elementId = element.id;
        }

        super(diagram, data);
        this.elementId = data.elementId;

        this.parentViewId$.next(data.parentViewId);

        this.x$ = new PropertySubject<number>(data.x ?? 0);
        this.y$ = new PropertySubject<number>(data.y ?? 0);
        this.width$ = new PropertySubject<number>(data.width ?? 120);
        this.height$ = new PropertySubject<number>(data.height ?? 60);

        diagram.elementViews$.set(this.id, this);
    }
}