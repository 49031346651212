import {
    IWorkspace,
    IWorkspaceItem
} from "../abstractions";
import {ModelingProject} from "../../modeling/models/ModelingProject";
import {ImportContext} from "../../import/services/ImportContext";
import {ModellingProjectSummary} from "../../modeling/models/ModellingProjectSummary";
import {ModellingLocalStorage} from "../../modeling/models/ModellingLocalStorage";
import {IModelingProject} from "../../modeling/abstractions";
import {
    CollectionSubject,
    DictionarySubject,
    ICollectionSubject,
    IDictionarySubject,
    IPropertySubject, PropertySubject
} from "../../lib/rxjs-ex";
import {IModelingProjectContentData} from "../../modeling/persistence";

export class WorkspaceService implements IWorkspace {
    public readonly ProjectSummaries$: ICollectionSubject<IWorkspaceItem> = new CollectionSubject<IWorkspaceItem>();
    public readonly Projects$: IDictionarySubject<IModelingProject> = new DictionarySubject<IModelingProject>();

    public get ProjectSummaries() {
        return this.ProjectSummaries$.value;
    }

    readonly isAuthenticated: IPropertySubject<boolean>;
    readonly isLoading: IPropertySubject<boolean>;

    constructor() {
        this.isAuthenticated = new PropertySubject<boolean>(false);
        this.isLoading = new PropertySubject<boolean>(false);

        const projectIds = ModellingLocalStorage.ReadProjectIds();
        projectIds.map(id => this.loadProjectSummary(id));
    }

    async tryImportProject(acceptedFiles: File[]) {
        const context = new ImportContext();
        await context.tryImportFiles(acceptedFiles);
        if (context.hasErrors) {
            console.error("import failed", context.errors);
            return;
        }
        for (const rootResource of context.importRootResources) {
            const elementData = rootResource.elementData;
            if (!elementData) continue;

            const targetProject = this.loadOrCreateProject(elementData.id);
            targetProject.rootElement.readFromData(elementData);
            targetProject.save();
        }
    }

    private loadOrCreateProject(id?: string) {
        if (id) {
            const loaded = this.Projects$.get(id);
            if (loaded) return loaded;
        }

        const summary = this.loadOrCreateProjectSummary(id);
        const result = new ModelingProject(summary);
        this.Projects$.set(summary.Id, result);
        return result;
    }

    public loadProject(projectId: string | undefined): IModelingProject | undefined {

        const summary = this.loadProjectSummary(projectId);
        if (!summary) return undefined;

        return new ModelingProject(summary);
    }

    private loadOrCreateProjectSummary(id?: string): IWorkspaceItem {
        return this.loadProjectSummary(id) ?? this.createProjectSummary(id);
    }

    private createProjectSummary(id?: string): IWorkspaceItem {
        const projectSummaries = this.ProjectSummaries$.value;
        let name = `New Project`;
        let i = 1;
        while (projectSummaries.find(item => item.Name$.value === name))
            name = `New Project ${i++}`;

        const summary = new ModellingProjectSummary(this, {id, name});
        this.ProjectSummaries$.next([...this.ProjectSummaries, summary]);
        return summary;
    }

    private loadProjectSummary(id?: string): IWorkspaceItem | undefined {
        if (!id)
            return undefined;

        const existing = this.ProjectSummaries.find(project => project.Id === id);
        if (existing)
            return existing;

        const data = ModellingLocalStorage.ReadProjectSummaryData(id);
        if (!data)
            return undefined;
        const result = new ModellingProjectSummary(this, data);
        this.ProjectSummaries$.next([...this.ProjectSummaries, result]);
        return result;
    }
}
