import {useApi} from "../../shared/services/Api";
import Box from "@mui/material/Box";
import {
    QueryArchimateRequestBody,
    QueryArchimateResponse
} from "../../_generated/aiaea/v1";
import {useEffect, useState} from "react";
import {bind} from "../../lib/react-rxjs-ex";
import {IModelingDiagram} from "../abstractions";
import {useModelingDiagramContext} from "./ModelingDiagramProvider";
import {
    IModelingElementData,
    IModelingRelationshipData
} from "../persistence";
import ArchimateElementPredictionView from "../../archimate/components/diagram/ArchimateElementPredictionView";

const [useDiagramElementViews] = bind((diagram: IModelingDiagram) => diagram.elementViews$);
const [useDiagramRelationshipViews] = bind((diagram: IModelingDiagram) => diagram.relationshipViews$);

function ProjectPredictorPartialContent() {
    const {diagram} = useModelingDiagramContext();

    const api = useApi();

    const elementViews = useDiagramElementViews(diagram);
    const relationshipViews = useDiagramRelationshipViews(diagram);

    const [request, setRequest] = useState<QueryArchimateRequestBody>({
        email: api.user?.email,
    });
    const [response, setResponse] = useState<QueryArchimateResponse>({});

    useEffect(() => {
        const nextRequest: QueryArchimateRequestBody = {
            email: api.user?.email,
            elements: Object.values(elementViews).map(elementView => {
                const element = elementView.element;
                const data: IModelingElementData = {
                    id: element.id,
                };
                element.writeToData(data);
                return data;
            }),
            relationships: Object.values(relationshipViews).map(relationshipView => {
                const relationship = relationshipView.relationship;
                const data: IModelingRelationshipData = {
                    id: relationship.id,
                };
                relationship.writeToData(data);
                return data;
            }),
        };

        if (JSON.stringify(nextRequest) !== JSON.stringify(request))
            setRequest(nextRequest);
    }, [api, elementViews, relationshipViews]);

    useEffect(() => {
        if (!api.predictorApi)
            return;
        api.predictorApi
            .queryArchimate({queryArchimateRequestBody: request})
            .subscribe(next => {
                if (JSON.stringify(next) !== JSON.stringify(response))
                    setResponse(next);
            });
    }, [request]);

    return <>
        <div>Project Predictor</div>
        {response.elements?.map(element =>
            <ArchimateElementPredictionView
                element={element}
                onDragStart={(ea) => {
                    ea.dataTransfer.setData('application/elements', JSON.stringify({element}));
                    console.log(ea.dataTransfer.getData('application/elements'));
                }}/>)}
    </>
}

export function ProjectPredictorPartial() {

    var api = useApi();

    return <Box sx={{
        minHeight: '100%',
        maxHeight: '100%',
        width: '175px',
        minWidth: '175px',
        maxWidth: '175px',
        flexGrow: 0,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    }}>
        {api.isLoading ? <div>Loading...</div>
            : !api.isAuthenticated ? <div>Login for AI assistance.</div>
                : <ProjectPredictorPartialContent/>}
    </Box>
}