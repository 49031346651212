import {
    IModelingProjectItem,
    IModelingElement,
    IModelingProject
} from "../abstractions";
import {
    CollectionSubject,
    DictionarySubject,
    IPropertySubject,
    PropertyMappedSubject,
    PropertySubject
} from "../../lib/rxjs-ex";
import {IModelingProjectItemData} from "../persistence";

export abstract class ModelingProjectItem implements IModelingProjectItem {
    public readonly id: string;
    public readonly name$: PropertySubject<string | undefined>;
    public readonly attributes$: DictionarySubject<string>;

    public readonly stereotypes$ = new DictionarySubject<CollectionSubject<string>>();

    public addStereotypes(notation: string, values: string[]) {
        const stereotypes = this.stereotypes$;
        let notationStereotypes = stereotypes.get(notation);
        if (!notationStereotypes) {
            notationStereotypes = new CollectionSubject<string>(values);
            stereotypes.set(notation, notationStereotypes);
        } else {
            notationStereotypes.add(...values);
        }
    }

    public readonly parentId$: IPropertySubject<string | undefined>;
    public readonly parent$: IPropertySubject<IModelingElement | undefined>;

    protected readFromData(data: IModelingProjectItemData) {
        if (data.name)
            this.name$.next(data.name);

        if (data.attributes)
            this.attributes$.setAll(data.attributes);

        if (data.stereotypes)
            for (const [notation, values] of Object.entries(data.stereotypes ?? {}))
                this.addStereotypes(notation, values);

        if (data.parentId)
            this.parentId$.next(data.parentId);
    }

    protected writeToData(data: IModelingProjectItemData) {
        if(this.parentId$.value)
            data.parentId = this.parentId$.value;

        if (this.name$.value)
            data.name = this.name$.value;

        if (Object.entries(this.attributes$.value).length > 0)
            data.attributes = this.attributes$.value;

        if (Object.entries(this.stereotypes$.value).length > 0) {
            data.stereotypes = {};
            for (const [notation, values] of Object.entries(this.stereotypes$.value))
                data.stereotypes[notation] = values.value;
        }
    }

    protected abstract addThisToParent(element: IModelingElement): void;

    protected abstract removeThisFromParent(element: IModelingElement): void;

    protected constructor(public readonly project: IModelingProject, data: IModelingProjectItemData) {
        this.id = data.id ?? crypto.randomUUID();
        this.name$ = new PropertySubject<string | undefined>(data.name);
        this.attributes$ = new DictionarySubject<string>(data.attributes);

        for (const [notation, values] of Object.entries(data.stereotypes ?? {}))
            this.addStereotypes(notation, values);

        this.parentId$ = new PropertySubject<string | undefined>(data.parentId);
        this.parent$ = new PropertyMappedSubject<string | undefined, IModelingElement | undefined>(this.parentId$, id => this.project.allElements$.get(id), element => element?.id);

        this.parent$.change((oldValue, newValue) => {
            this.removeThisFromParent(oldValue ?? this.project.rootElement);
            this.addThisToParent(newValue ?? this.project.rootElement);
        });

        this.addThisToParent(this.parent$.value ?? this.project.rootElement);
    }
}
