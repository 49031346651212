import {
    IModelingDiagram,
    IModelingDiagramItemView,
    IModelingDiagramRelationshipView
} from "../abstractions";
import {ModelingDiagramItemView} from "./ModelingDiagramItemView";
import {
    IPropertySubject,
    PropertyMappedSubject,
    PropertySubject
} from "../../lib/rxjs-ex";
import {IModelingDiagramRelationshipViewData} from "../persistence";

export class ModelingDiagramRelationshipView extends ModelingDiagramItemView implements IModelingDiagramRelationshipView {
    public readonly relationshipId: string;

    public get relationship() {
        return this.diagram.project.getOrCreateRelationship({
            id: this.relationshipId,
        });
    }

    public readonly sourceViewId$: IPropertySubject<string | undefined>;
    public readonly sourceView$: IPropertySubject<IModelingDiagramItemView | undefined>;

    public readonly targetViewId$: IPropertySubject<string | undefined>;
    public readonly targetView$: IPropertySubject<IModelingDiagramItemView | undefined>;

    readFromData(data: IModelingDiagramRelationshipViewData) {
        super.readFromData(data);

        if (data.sourceViewId)
            this.sourceViewId$.next(data.sourceViewId);
        if (data.targetViewId)
            this.targetViewId$.next(data.targetViewId);
    }

    writeToData(data: IModelingDiagramRelationshipViewData) {
        super.writeToData(data);

        data.relationshipId = this.relationshipId;

        data.sourceViewId = this.sourceViewId$.value;
        data.targetViewId = this.targetViewId$.value;
    }

    constructor(diagram: IModelingDiagram, data: IModelingDiagramRelationshipViewData) {
        if (!data.relationshipId) throw new Error("Spec must have a relationship.");
        if (!data.sourceViewId) throw new Error("Spec must have a source view.");
        if (!data.targetViewId) throw new Error("Spec must have a target view.");

        super(diagram, data);

        this.relationshipId = data.relationshipId;

        this.sourceViewId$ = new PropertySubject<string | undefined>(data.sourceViewId);
        this.sourceView$ = new PropertyMappedSubject<string | undefined, IModelingDiagramItemView | undefined>(this.sourceViewId$,
            id => this.diagram.getDiagramItemView(id),
            view => view?.id);

        this.targetViewId$ = new PropertySubject<string | undefined>(data.targetViewId);
        this.targetView$ = new PropertyMappedSubject<string | undefined, IModelingDiagramItemView | undefined>(this.targetViewId$,
            id => this.diagram.getDiagramItemView(id),
            view => view?.id);

        diagram.relationshipViews$.set(this.id, this);
    }
}
