import {createContext, ReactNode, useContext} from "react";
import {
    IModelingDiagram,
    IModelingElement,
    IModelingProject,
    IModelingProjectItem,
    IModelingRelationship
} from "../abstractions";
import {IDictionarySubject, IPropertySubject, PropertySubject} from "../../lib/rxjs-ex";
import {
    IModelingDiagramData,
    IModelingElementData,
    IModelingProjectContentData,
    IModelingRelationshipData
} from "../persistence";

class defaultModelingProject implements IModelingProject {
    get rootElement(): IModelingElement {
        throw new Error("Not implemented");
    }

    get allElements$(): IDictionarySubject<IModelingElement> {
        throw new Error("Not implemented");
    }

    get allRelationships$(): IDictionarySubject<IModelingRelationship> {
        throw new Error("Not implemented");
    }

    get allDiagrams$(): IDictionarySubject<IModelingDiagram> {
        throw new Error("Not implemented");
    }

    getOrCreateDiagram(data: IModelingDiagramData): IModelingDiagram {
        throw new Error("Not implemented");
    }

    canGetOrCreateElement(data: IModelingElementData): boolean {
        return false;
    }

    getOrCreateElement(data: IModelingElementData): IModelingElement {
        throw new Error("Not implemented");
    }

    getOrCreateRelationship(data: IModelingRelationshipData): IModelingRelationship {
        throw new Error("Not implemented");
    }

    getProjectItem(id?: string): IModelingProjectItem | undefined {
        throw new Error("Not implemented");
    }

    readFromData(data: IModelingProjectContentData): void {
        throw new Error("Not implemented");
    }

    save(): void {
        throw new Error("Not implemented");
    }

    writeToData(data: IModelingProjectContentData): void {
        throw new Error("Not implemented");
    }
}

interface IModelingProjectContext {
    project: IModelingProject;
    tabs$: IPropertySubject<IModelingDiagram[]>;
    activeTab$: IPropertySubject<string>;
}

const defaultValue = {
    project: new defaultModelingProject(),
    tabs$: new PropertySubject<IModelingDiagram[]>([]),
    activeTab$: new PropertySubject<string>("")
}

const context = createContext<IModelingProjectContext>(defaultValue);

export function useModelingProjectContext() {
    return useContext(context);
}

export function ModelingProjectProvider({children, project}: {
    project: IModelingProject;
    children: ReactNode;
}) {
    const value = {
        project: project,
        tabs$: new PropertySubject([]),
        activeTab$: new PropertySubject("")
    }
    return <context.Provider value={value}>{children}</context.Provider>;
}
