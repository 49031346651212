import {ModelingProjectItem} from "./ModelingProjectItem";
import {
    IModelingDiagram,
    IModelingDiagramElementView,
    IModelingDiagramItemView,
    IModelingDiagramRelationshipView, IModelingElement,
    IModelingProject
} from "../abstractions";
import {ModelingDiagramRelationshipView} from "./ModelingDiagramRelationshipView";
import {ModelingDiagramElementView} from "./ModelingDiagramElementView";
import {DictionarySubject} from "../../lib/rxjs-ex";
import {
    IModelingDiagramElementViewData,
    IModelingDiagramRelationshipViewData,
    IModelingDiagramData
} from "../persistence";

export class ModelingDiagram extends ModelingProjectItem implements IModelingDiagram {
    public readonly elementViews$ = new DictionarySubject<IModelingDiagramElementView>();

    public getOrCreateElementView(data: IModelingDiagramElementViewData): IModelingDiagramElementView {
        return this.elementViews$.get(data.id) ?? new ModelingDiagramElementView(this, data);
    }

    public readonly relationshipViews$ = new DictionarySubject<IModelingDiagramRelationshipView>();

    public getOrCreateRelationshipView(data: IModelingDiagramRelationshipViewData): IModelingDiagramRelationshipView {
        return this.relationshipViews$.get(data.id) ?? new ModelingDiagramRelationshipView(this, data);
    }

    getDiagramItemView(id?: string): IModelingDiagramItemView | undefined {
        if (!id) return undefined;

        return this.elementViews$.get(id) ?? this.relationshipViews$.get(id);
    }

    readFromData(data: IModelingDiagramData): void {
        super.readFromData(data);
        this.readElementViews(data);
        this.readRelationshipViews(data);
    }

    writeToData(data: IModelingDiagramData): void {
        super.writeToData(data);
        this.writeElementViews(data);
        this.writeRelationshipViews(data);
    }

    private readElementViews(data: IModelingDiagramData) {
        if (!data.relationshipViews) return;

        for (const elementViewId in data.elementViews) {
            const elementView = data.elementViews[elementViewId];
            this.getOrCreateElementView({
                ...elementView,
                id: elementViewId
            }).readFromData({
                ...elementView
            });
        }
    }

    private writeElementViews(data: IModelingDiagramData) {
        for (const [elementViewId, elementView] of Object.entries(this.elementViews$.value)) {
            const elementData: IModelingDiagramElementViewData = {};
            elementView.writeToData(elementData);
            data.elementViews = {
                ...data.elementViews,
                [elementViewId]: elementData
            }
        }
    }

    private readRelationshipViews(data: IModelingDiagramData) {
        if (!data.relationshipViews) return;

        for (const relationshipViewId in data.relationshipViews) {
            const relationshipView = data.relationshipViews[relationshipViewId];
            this.getOrCreateRelationshipView({
                ...relationshipView,
                id: relationshipViewId
            }).readFromData({
                ...relationshipView
            });
        }
    }

    private writeRelationshipViews(data: IModelingDiagramData) {
        for (const [relationshipViewId, relationshipView] of Object.entries(this.relationshipViews$.value)) {
            const relationshipData: IModelingDiagramRelationshipViewData = {};
            relationshipView.writeToData(relationshipData);
            data.relationshipViews = {
                ...data.relationshipViews,
                [relationshipViewId]: relationshipData
            }
        }
    }

    protected override addThisToParent(element: IModelingElement): void {
        element.diagramIds$.add(this.id);
    }

    protected override removeThisFromParent(element: IModelingElement): void {
        element.diagramIds$.remove(this.id);
    }

    constructor(project: IModelingProject, data: IModelingDiagramData) {
        super(project, data);
        this.project.allDiagrams$.set(this.id, this);

        this.readFromData(data);
    }
}