import {
    IModelingDiagram,
    IModelingElement,
    IModelingProject,
    IModelingRelationship
} from "../abstractions";
import {ModelingProjectItem} from "./ModelingProjectItem";
import {
    CollectionMappedSubject,
    CollectionSubject
} from "../../lib/rxjs-ex";
import {
    IModelingDiagramData,
    IModelingElementData,
    IModelingRelationshipData
} from "../persistence";

export class ModelingElement extends ModelingProjectItem implements IModelingElement {

    public readonly elementIds$ = new CollectionSubject<string>();
    public readonly elements$ = new CollectionMappedSubject<string, IModelingElement>(
        this.elementIds$,
        id => this.getOrCreateElement({id: id, parentId: this.id}),
        element => element.id
    );

    public getOrCreateElement(spec: IModelingElementData): IModelingElement {
        return this.project.getOrCreateElement({...spec, parentId: this.id});
    }


    public readonly relationshipIds$ = new CollectionSubject<string>();
    public readonly relationships$ = new CollectionMappedSubject<string, IModelingRelationship>(
        this.relationshipIds$,
        id => this.getOrCreateRelationship({id: id, parentId: this.id}),
        relationship => relationship.id
    );

    public getOrCreateRelationship(spec: IModelingRelationshipData): IModelingRelationship {
        return this.project.getOrCreateRelationship({...spec, parentId: this.id});
    }


    public readonly diagramIds$ = new CollectionSubject<string>();
    public readonly diagrams$ = new CollectionMappedSubject<string, IModelingDiagram>(
        this.diagramIds$,
        id => this.getOrCreateDiagram({id: id, parentId: this.id}),
        diagram => diagram.id
    );

    public getOrCreateDiagram(spec: IModelingDiagramData): IModelingDiagram {
        return this.project.getOrCreateDiagram({...spec, parentId: this.id});
    }

    public readFromData(data: IModelingElementData) {
        super.readFromData(data);

        this.readElements(data);
        this.readRelationships(data);
        this.readDiagrams(data);
    }

    public writeToData(data: IModelingElementData) {
        super.writeToData(data);

        this.writeElements(data);
        this.writeRelationships(data);
        this.writeDiagrams(data);
    }

    private readElements(data: IModelingElementData) {
        if (!data.elements) return;

        for (let [elementId, elementData] of Object.entries(data.elements)) {
            elementData = {
                ...elementData,
                parentId: this.id,
                id: elementId
            };
            this.getOrCreateElement(elementData).readFromData(elementData);
        }
    }

    private writeElements(contentData: IModelingElementData) {
        const elements = this.elements$.value;
        for (const element of elements) {
            const elementData: IModelingElementData = {};
            element.writeToData(elementData);
            contentData.elements = {
                ...contentData.elements,
                [element.id]: elementData
            };
        }
    }

    private readRelationships(data: IModelingElementData) {
        if (!data.relationships) return;

        for (let [relationshipId, relationshipData] of Object.entries(data.relationships)) {
            relationshipData = {
                ...relationshipData,
                parentId: this.id,
                id: relationshipId
            };
            this.getOrCreateRelationship(relationshipData).readFromData(relationshipData);
        }
    }

    private writeRelationships(contentData: IModelingElementData) {
        const relationships = this.relationships$.value;
        for (const relationship of relationships) {
            const relationshipData: IModelingRelationshipData = {};
            relationship.writeToData(relationshipData);
            contentData.relationships = {
                ...contentData.relationships,
                [relationship.id]: relationshipData
            };
        }
    }

    private readDiagrams(data: IModelingElementData) {
        if (!data.diagrams) return;

        for (let [diagramId, diagramData] of Object.entries(data.diagrams)) {
            diagramData = {
                ...diagramData,
                parentId: this.id,
                id: diagramId
            };
            this.getOrCreateDiagram(diagramData).readFromData(diagramData);
        }
    }

    private writeDiagrams(contentData: IModelingElementData) {
        const diagrams = this.diagrams$.value;
        for (const diagram of diagrams) {
            const diagramData: IModelingDiagramData = {};
            diagram.writeToData(diagramData);
            contentData.diagrams = {
                ...contentData.diagrams,
                [diagram.id]: diagramData
            };
        }
    }

    protected override addThisToParent(element?: IModelingElement): void {
        if (!element) return;
        element.elementIds$.add(this.id);
    }

    protected override removeThisFromParent(element?: IModelingElement): void {
        if (!element) return;
        element.elementIds$.remove(this.id);
    }

    constructor(project: IModelingProject, data: IModelingElementData) {
        super(project, data);
        this.project.allElements$.set(this.id, this);
    }
}
