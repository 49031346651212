export class Xml {
    private static createFromText(text: Text) {
        return text.textContent ?? undefined;
    }

    public static createFromElement(element: Element) {
        const result: { [key: string]: any } = {};
        for (const attributeName of element.getAttributeNames()) {
            if(attributeName === "xmlns" || attributeName === "xsi:type" || attributeName.startsWith("xmlns:")) continue;
            result[attributeName] = element.getAttribute(attributeName) ?? "";
        }

        for (let i = 0; i < element.childNodes.length; i++) {
            this.addFromChildNode(result, element.childNodes[i]);
        }

        return result;
    }

    private static addFromChildNode(result: { [p: string]: any }, childNode: Node) {
        switch (childNode.nodeType) {
            case undefined:
                return;
            case Node.TEXT_NODE:
                return this.addFromChildText(result, childNode as Text);
            case Node.ELEMENT_NODE:
                return this.addFromChildElement(result, childNode as Element);
            default: {
                throw new Error(`Unsupported XML node type ${childNode.nodeType}`);
            }
        }
    }

    private static addFromChildText(result: { [p: string]: any }, childText: Text) {
        if (!childText.textContent || childText.textContent.trim().length === 0)
            return;

        let texts: string[] | undefined = result['#texts'];
        if (texts) {
            texts.push(childText.textContent);
        } else {
            result['#texts'] = [childText.textContent];
        }
    }

    private static addFromChildElement(result: { [p: string]: any }, childElement: Element) {
        const collectionName = childElement.localName;

        let items: any[] | undefined = result[collectionName];
        if (items) {
            items.push(this.createFromElement(childElement));
        } else {
            result[collectionName] = [this.createFromElement(childElement)];
        }
    }
}
