import {CssBaseline, Theme} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "./AppBar";
import {Outlet} from "react-router-dom";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
import React from "react";

const defaultTheme = createTheme();

export default function DefaultLayout() {
    return (
        <div className={"App"}>
            <CssBaseline/>
            <ThemeProvider theme={defaultTheme}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    maxHeight: '100vh',
                    overflowY: 'hidden',
                }}>
                    <AppBar/>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[200]
                                    : theme.palette.grey[800],
                            flexGrow: 1,
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Outlet/>
                    </Box>
                    <Box component={'footer'}
                         sx={{p: 2, flexGrow: 0, bgcolor: 'background.paper', textAlign: 'center'}}>
                        <Container maxWidth="lg">
                            <Copyright org={"binario.one"} url={"https://binario.one"} startYear={2023}/>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </div>
    );
}