import {useParams} from "react-router-dom";
import ModelingProjectTreePartial from "./ModelingProjectTreePartial";
import ProjectElementBoxPartial from "./ProjectElementBoxPartial";
import {useWorkspace} from "../../workspace/components/WorkspaceProvider";
import {ModelingProjectProvider} from "./ModelingProjectProvider";
import {ModelingProjectMainPartial} from "./ModelingProjectMainPartial";

export default function ModelingProjectViewPage() {
    const {projectId} = useParams<{
        projectId: string;
    }>();

    const workspace = useWorkspace();
    const project = workspace.loadProject(projectId);

    if (!project) {
        return (<div>Loading...</div>);
    }

    return (
        <ModelingProjectProvider project={project}>
            <ModelingProjectTreePartial />
            <ModelingProjectMainPartial />
        </ModelingProjectProvider>
    );
}