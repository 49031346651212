import {IWorkspace, IWorkspaceItem} from "../../workspace/abstractions";
import {ModellingLocalStorage} from "./ModellingLocalStorage";
import {IPropertySubject, PropertySubject} from "../../lib/rxjs-ex";
import {IWorkspaceItemData} from "../../workspace/persistence";

export class ModellingProjectSummary implements IWorkspaceItem {
    public readonly Id: string;

    public readonly Name$: IPropertySubject<string>;

    public readonly CreateDateTime: Date;

    public readonly UpdateDateTime$: IPropertySubject<Date>;
    public readonly AccessDateTime$: IPropertySubject<Date>;

    Updated(now?: Date): void {
        if (!now)
            now = new Date(Date.now());

        this.UpdateDateTime$.next(now);
        this.AccessDateTime$.next(now);
        this.WriteToCache();
    }

    private WriteToCache() {
        const ids = ModellingLocalStorage.ReadProjectIds();
        if (!ids.find(id => id === this.Id)) {
            ids.push(this.Id);
            ModellingLocalStorage.WriteProjectIds(ids);
        }
        ModellingLocalStorage.WriteProjectSummaryData(this);
    }

    public static ReadFromCache(workspace: IWorkspace, id: string) {
        const summaryData = ModellingLocalStorage.ReadProjectSummaryData(id);
        if (!summaryData)
            return null;
        return new ModellingProjectSummary(workspace, summaryData);
    }

    constructor(workspace: IWorkspace, summaryData?: IWorkspaceItemData) {
        let id = summaryData?.id;
        while (!id || workspace.ProjectSummaries$.value.find(summary => summary.Id === id))
            id = crypto.randomUUID();
        this.Id = id;

        this.CreateDateTime = new Date(summaryData?.createDateTime ?? Date.now());

        let name = summaryData?.name;
        let i = 1;
        while (!name || workspace.ProjectSummaries$.value.find(summary => summary.Name$.value === name))
            name = `New Project ${i++}`;

        this.Name$ = new PropertySubject<string>(name);
        this.UpdateDateTime$ = new PropertySubject<Date>(summaryData?.updateDateTime ? new Date(summaryData.updateDateTime) : this.CreateDateTime);
        this.AccessDateTime$ = new PropertySubject<Date>(summaryData?.accessDateTime ? new Date(summaryData.accessDateTime) : this.UpdateDateTime$.value);

        if (!summaryData?.createDateTime)
            this.WriteToCache();
    }
}