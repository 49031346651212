import {RouteObject} from "react-router-dom";
import UserViewPage from "./UserViewPage";
import UserEditPage from "./UserEditPage";
import UserLogoutPage from "./UserLogoutPage";
import UserLoginPage from "./UserLoginPage";

export function getUserRoutes(): RouteObject[] {
    return [
        {
            path: 'user',
            element: <UserViewPage/>
        },
        {
            path: 'user/edit',
            element: <UserEditPage/>,
        },
        {
            path: 'user/login',
            element: <UserLoginPage />,
        },
        {
            path: 'user/logout',
            element: <UserLogoutPage/>,
        }
    ];
}