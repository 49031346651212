export class Path {
    static getFileName(path: string) {
        const parts = Path.split(path);
        return parts[parts.length - 1];
    }

    static getDirectoryName(path?: string) {
        if(!path) return;

        const parts = Path.split(path);
        if (parts.length <= 1) return;

        parts.splice(parts.length - 1);
        return Path.combine(...parts);
    }

    public static getExtension(path: string) {
        const fileName = Path.getFileName(path);
        if (fileName.indexOf('.') < 0) return null;
        return fileName.toLowerCase().split('.').pop();
    }

    public static combine(...paths: string[]) {
        const parts = paths.map(p => Path.split(p)).reduce((a, b) => a.concat(b), []);
        const result = [];
        for (const part of parts) {
            if (part === "") continue;
            if (part === ".") continue;
            if (part === "..") result.pop();
            else result.push(part);
        }
        return result.join('/');
    }

    static split(path?: string) {
        if(!path) return [];
        return path.split(/[\\/]+/);
    }
}
