// tslint:disable
/**
 * Aiaea API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI, throwIfNullOrUndefined, encodeURI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    Project,
    ProjectSummary,
} from '../models';

export interface GetProjectRequest {
    id: string;
}

/**
 * no description
 */
export class ProjectsApi extends BaseAPI {

    /**
     * List all projects for the current user.
     */
    getProject({ id }: GetProjectRequest): Observable<Project>
    getProject({ id }: GetProjectRequest, opts?: OperationOpts): Observable<AjaxResponse<Project>>
    getProject({ id }: GetProjectRequest, opts?: OperationOpts): Observable<Project | AjaxResponse<Project>> {
        throwIfNullOrUndefined(id, 'id', 'getProject');

        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // Bearer authentication
        };

        return this.request<Project>({
            url: '/_api/Projects/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * List all projects for the current user.
     */
    listProjects(): Observable<Array<ProjectSummary>>
    listProjects(opts?: OperationOpts): Observable<AjaxResponse<Array<ProjectSummary>>>
    listProjects(opts?: OperationOpts): Observable<Array<ProjectSummary> | AjaxResponse<Array<ProjectSummary>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.apiKey && { 'Authorization': this.configuration.apiKey('Authorization') }), // Bearer authentication
        };

        return this.request<Array<ProjectSummary>>({
            url: '/_api/Projects',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
