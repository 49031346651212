import {ReactNode, createContext, useContext} from "react";
import {useAuth0, User} from "@auth0/auth0-react";
import {
    Configuration,
    ConfigurationParameters,
    ProjectsApi,
    PredictorApi
} from "../../_generated/aiaea/v1";
import {PropertySubject} from "../../lib/rxjs-ex";
import {Auth0ContextInterface} from "@auth0/auth0-react/src/auth0-context";
import {bind} from "../../lib/react-rxjs-ex";

export interface TApi {
    isLoading: boolean;
    isAuthenticated: boolean;
    user?: User;
    projectsApi?: ProjectsApi;
    predictorApi?: PredictorApi;
}

const loadingApi: TApi = {
    isLoading: true,
    isAuthenticated: false
};

const anonymousApi: TApi = {
    isLoading: false,
    isAuthenticated: false
};

class TApiContextValue {
    readonly auth0$ = new PropertySubject<{auth0?: Auth0ContextInterface | undefined, apiUrl?: string}>({});
    readonly api$ = new PropertySubject<TApi>(anonymousApi);

    constructor() {
        this.auth0$.subscribe(next => {
            const auth0 = next.auth0;
            if (!auth0 || auth0.isLoading) {
                console.log("Loading API");
                this.api$.next(loadingApi);
                return;
            }

            if (!auth0.isAuthenticated || !auth0.user) {
                console.log("Anonymous API");
                this.api$.next(anonymousApi);
                return;
            }

            console.debug("Authenticating API: " + next.apiUrl);

            auth0.getAccessTokenSilently().then(accessToken => {
                const configuration = new Configuration({
                    apiKey: `Bearer ${accessToken}`,
                    basePath: next.apiUrl
                } as ConfigurationParameters);

                const api: TApi = {
                    isLoading: false,
                    isAuthenticated: true,
                    user: auth0.user,
                    projectsApi: new ProjectsApi(configuration),
                    predictorApi: new PredictorApi(configuration)
                };

                this.api$.next(api);
                console.log("Authenticated API");
            });
        });
    }
}

const apiContextValue = new TApiContextValue();

const context = createContext<TApiContextValue>(apiContextValue);

const [useApiBinding] = bind((contextValue: TApiContextValue) => contextValue.api$);

export function useApi() {
    const contextValue = useContext(context);
    return useApiBinding(contextValue);
}

export function ApiProvider({children, apiUrl}: {
    children: ReactNode;
    apiUrl: string;
}) {
    const auth0 = useAuth0();
    apiContextValue.auth0$.next({auth0, apiUrl});

    return <context.Provider value={apiContextValue}>{children}</context.Provider>;
}