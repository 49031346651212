import {IModelingElement, IModelingProjectItem} from "../../../modeling/abstractions";
import {ArchimateRelationshipTreeItem} from "./ArchimateRelationshipTreeItem";
import {bind} from "../../../lib/react-rxjs-ex";
import {ArchimateDiagramTreeItem} from "./ArchimateDiagramTreeItem";

import * as React from 'react';

import {ModelingItemTreeItem} from "./ModelingItemTreeItem";

const layers = [
    "motivation",
    "strategy",
    "business",
    "application",
    "technology",
    "implementation_migration"
];

const names = [
    "Other",
    "Relations",
    "Views"
];

function layerIndex(item: IModelingProjectItem) {
    const stereotypes = item.stereotypes$.get("archimate");
    if (!stereotypes) return -1;

    for (const stereotype of stereotypes.value) {
        const index = layers.indexOf(stereotype);
        if (index >= 0) return index;
    }

    return -1;
}

function nameIndex(item: IModelingProjectItem) {
    if (!item.name$.value)
        return -1;

    return names.indexOf(item.name$.value);
}

function compareElements(a: IModelingElement, b: IModelingElement): number {
    {
        const aIndex = layerIndex(a);
        const bIndex = layerIndex(b);
        if (aIndex !== bIndex) {
            if (aIndex < 0) return 1;
            if (bIndex < 0) return -1;
            return aIndex - bIndex;
        }
    }

    {
        const aIndex = nameIndex(a);
        const bIndex = nameIndex(b);
        if (aIndex !== bIndex) {
            if (aIndex < 0) return 1;
            if (bIndex < 0) return -1;
            return aIndex - bIndex;
        }
    }

    if (a.name$.value === b.name$.value) return 0;
    if (!a.name$.value) return 1;
    if (!b.name$.value) return -1;
    if (a.name$.value < b.name$.value) return -1;
    if (a.name$.value > b.name$.value) return 1;
    return 0;
}

const [useElements] = bind((item: IModelingElement) => item.elements$);
const [useRelationships] = bind((item: IModelingElement) => item.relationships$);
const [useDiagrams] = bind((item: IModelingElement) => item.diagrams$);

export function ArchimateElementTreeItem({element}: { element: IModelingElement }) {
    const elements$ = useElements(element);
    const relationships$ = useRelationships(element);
    const diagrams$ = useDiagrams(element);

    if (elements$.length === 0 && relationships$.length === 0 && diagrams$.length === 0) {
        return <ModelingItemTreeItem item={element}/>;
    }

    var elements = [...elements$].sort(compareElements);

    return <ModelingItemTreeItem item={element}>
        {elements.map(element => <ArchimateElementTreeItem element={element}/>)}
        {relationships$.map(relationship => <ArchimateRelationshipTreeItem relationship={relationship}/>)}
        {diagrams$.map(diagram => <ArchimateDiagramTreeItem diagram={diagram}/>)}
    </ModelingItemTreeItem>;
}