import {ModelingProjectItem} from "./ModelingProjectItem";
import {
    IModelingElement,
    IModelingProject,
    IModelingProjectItem,
    IModelingRelationship
} from "../abstractions";
import {
    IPropertySubject,
    PropertyMappedSubject,
    PropertySubject
} from "../../lib/rxjs-ex";
import {IModelingRelationshipData} from "../persistence";

export class ModelingRelationship extends ModelingProjectItem implements IModelingRelationship {
    public readonly sourceId$: IPropertySubject<string | undefined>;
    public readonly source$: IPropertySubject<IModelingProjectItem | undefined>;

    public readonly targetId$: IPropertySubject<string | undefined>;
    public readonly target$: IPropertySubject<IModelingProjectItem | undefined>;

    public readFromData(data: IModelingRelationshipData) {
        super.readFromData(data);

        if (data.sourceId)
            this.sourceId$.next(data.sourceId);

        if (data.targetId)
            this.targetId$.next(data.targetId);
    }

    public writeToData(data: IModelingRelationshipData) {
        super.writeToData(data);

        data.sourceId = this.sourceId$.value;
        data.targetId = this.targetId$.value;
    }

    protected override addThisToParent(element: IModelingElement): void {
        element.relationshipIds$.add(this.id);
    }

    protected override removeThisFromParent(element: IModelingElement): void {
        element.relationshipIds$.remove(this.id);
    }

    constructor(project: IModelingProject, data: IModelingRelationshipData) {
        if (!data.sourceId || !data.targetId) throw new Error("Source and Target are required");

        super(project, data);
        this.project.allRelationships$.set(this.id, this);

        this.sourceId$ = new PropertySubject<string | undefined>(data.sourceId);
        this.source$ = new PropertyMappedSubject<string | undefined, IModelingProjectItem | undefined>(
            this.sourceId$,
            (id) => this.project.getProjectItem(id),
            (item) => item?.id
        );

        this.targetId$ = new PropertySubject<string | undefined>(data.targetId);
        this.target$ = new PropertyMappedSubject<string | undefined, IModelingProjectItem | undefined>(
            this.targetId$,
            (id) => this.project.getProjectItem(id),
            (item) => item?.id
        );
    }
}
