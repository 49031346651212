import {BlobReader, BlobWriter, TextWriter, ZipReader} from "@zip.js/zip.js";
import {IBlobImportLogic} from "./IBlobImportLogic";
import {ImportContext} from "./ImportContext";
import {Path} from "../../lib/util";

class ZipLoader implements IBlobImportLogic {
    public async TryImportBlob(context: ImportContext, path: string, blob: Blob) {
        const blobReader = new BlobReader(blob);
        const zipReader = new ZipReader(blobReader);
        try {
            const entries = await zipReader.getEntries();

            for (const index in entries) {
                const entry = entries[index];
                if (entry.directory) continue;
                const entryPath = Path.combine(path, entry.filename);
                await context.tryImport(entryPath,
                    () => entry.getData ? entry.getData(new TextWriter()) : undefined,
                    () => entry.getData ? entry.getData(new BlobWriter()) : undefined);
            }
        } finally {
            await zipReader.close();
        }
    }
}

const zipLoader = new ZipLoader();
export default zipLoader;