import {createContext, ReactNode, useContext} from "react";
import {IWorkspace} from "../abstractions";
import {WorkspaceService} from "../services/WorkspaceService";

const defaultValue = new WorkspaceService();

const context = createContext<IWorkspace>(defaultValue);

export function useWorkspace() {
    return useContext(context);
}

export function WorkspaceProvider({children}: {
    children: ReactNode;
}) {
    return <context.Provider value={defaultValue}>{children}</context.Provider>;
}
