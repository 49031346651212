import {BehaviorSubject} from "rxjs";
import {ICollectionSubject} from "./ICollectionSubject";

export class CollectionSubject<T> extends BehaviorSubject<T[]> implements ICollectionSubject<T> {
    private _changeHandler: { (oldValue: T[], newValue: T[]): void } | undefined;

    public add(...values: T[]): boolean {
        const oldValue = this.value;
        values = values.filter(v => oldValue.indexOf(v) < 0);
        if (values.length === 0) return false;

        const newValues = [...oldValue, ...values].sort();
        this.next(newValues);
        return true;
    }

    public remove(...values: T[]): boolean {
        const oldValues = this.value;
        const newValues = oldValues.filter(v => values.indexOf(v) < 0);
        if (newValues.length === oldValues.length) return false;

        this.next(newValues);
        return true;
    }

    override next(value: T[]): boolean {
        const oldValue = this.value;
        if (oldValue === value) return false;

        super.next(value);
        if (this._changeHandler) this._changeHandler(oldValue, value);
        return true;
    }

    public change(handler?: { (oldValue: T[], newValue: T[]): void } | undefined): void {
        this._changeHandler = handler;
    }

    public constructor(value?: T[]) {
        if (value) {
            value = value.filter((v, i, a) => a.indexOf(v) === i).sort();
            super(value);
        } else
            super([]);
    }
}
