import {BehaviorSubject} from "rxjs";
import {IDictionarySubject} from "./IDictionarySubject";

export class DictionarySubject<T> extends BehaviorSubject<{ [key: string]: T }> implements IDictionarySubject<T> {
    private _changeHandler: { (oldValue: { [p: string]: T }, newValue: { [p: string]: T }): void } | undefined;

    public set(key: string, value?: T): boolean {
        const currentValue = this.value;
        if (currentValue[key] === value) return false;

        const newValue = Object.assign({}, currentValue);
        if (value) newValue[key] = value;
        else delete newValue[key];
        return this.next(newValue);
    }

    public setAll(obj?: { [key: string]: T }): boolean {
        if (!obj) return false;

        const currentValue = this.value;
        let result = false;
        for (const i in obj) {
            if (currentValue[i] !== obj[i]) {
                result = true;
                break;
            }
        }
        if (!result) return false;

        const newValue = Object.assign({}, currentValue, obj);
        return this.next(newValue);
    }

    public get(key?: string): T | undefined {
        if (!key) return undefined;
        return this.value[key];
    }

    public next(value: { [key: string]: T }): boolean {
        const oldValue = this.value;
        if (oldValue === value) return false;

        for (const key in value) {
            if (oldValue[key] === value[key])
                delete value[key];
        }
        if (!Object.keys(value).length) return false;

        const newValue = Object.assign({}, oldValue, value);
        super.next(newValue);
        if (this._changeHandler) this._changeHandler(oldValue, value);
        return true;
    }

    public change(handler?: { (oldValue: { [p: string]: T }, newValue: { [p: string]: T }): void }): void {
        this._changeHandler = handler;
    }

    public constructor(value?: { [key: string]: T }) {
        if (value)
            super(Object.assign({}, value));
        else
            super({});
    }

    public has(key?: string) {
        if (!key) return false;

        return this.value.hasOwnProperty(key);
    }
}
