export default function IndexPage() {
    return (
        <p id="zero-state">
            <b>A</b>rtificial<br/>
            <b>I</b>ntelligence<br/>
            <b>A</b>ided<br/>
            <b>E</b>nterprise<br/>
            <b>A</b>rchitecture
        </p>
    );
}