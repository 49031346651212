import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import {
    redirect,
    LoaderFunctionArgs,
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import {
    createContact,
    getContacts
} from "./contacts";
import {Auth0Provider} from "@auth0/auth0-react";
import {StyledEngineProvider} from "@mui/material";
import {ApiProvider} from "./shared/services/Api";
import {WorkspaceProvider} from "./workspace/components/WorkspaceProvider";
import DefaultLayout from "./shared/layout/components/DefaultLayout";
import ErrorPage from "./shared/components/ErrorPage";
import {getUserRoutes} from "./user/components/UserNavigation";
import {getWorkspaceRoutes} from "./workspace/components/WorkspaceNavigation";
import IndexPage from "./shared/components/IndexPage";

export async function action() {
    const contact = await createContact();
    return redirect(`/contacts/${contact.id}/edit`);
}

export async function loader({request}: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const q = url.searchParams.get("q");
    const contacts = await getContacts(q);
    return {contacts, q};
}

const routes = [{
    path: '/',
    element: <DefaultLayout/>,
    errorElement: <ErrorPage/>,
    loader: loader,
    action: action,
    children: [
        {
            errorElement: <ErrorPage/>,
            children: getUserRoutes()
                .concat(getWorkspaceRoutes())
                .concat([
                    {
                        index: true,
                        element: <IndexPage/>
                    }])
        }
    ]
}];

const apiBaseAddress = window.location.origin === "https://dev.local:44407" ? "https://dev.local:7209" : window.location.origin;

const router = createBrowserRouter(routes);

export default function App() {
    return (
        <StyledEngineProvider injectFirst>
            <Auth0Provider domain={"binario-one.eu.auth0.com"}
                           clientId={"Xp89pyAPP1wJt90QrVGWyTgCHmsHioLq"}
                           authorizationParams={{
                               redirect_uri: window.location.origin
                           }}>
                <ApiProvider apiUrl={apiBaseAddress}>
                    <WorkspaceProvider>
                        <RouterProvider router={router}/>
                    </WorkspaceProvider>
                </ApiProvider>
            </Auth0Provider>
        </StyledEngineProvider>
    );
}