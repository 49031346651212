import {Handle, NodeProps, Position} from "reactflow";
import {IModelingDiagramElementView} from "../../../modeling/abstractions";

export function ArchimateElementView ({data}: NodeProps) {
    const elementView = data as IModelingDiagramElementView;
    const element = elementView.element;

    let stereotypes = element.stereotypes$.get("archimate")?.value ?? [];
    stereotypes = [...stereotypes, "icon"];

    const className = stereotypes.join(" ") ?? "";

    return (
        <div className="archimatenode">
            <div style={{
                height: elementView.height$.value,
                width: elementView.width$.value,
            }} className={className}>
                <div className="frame">
                    <div className="content">
                        {element.name$.value}
                    </div>
                </div>
                <Handle
                    position={Position.Left}
                    type="target"
                />
                <Handle
                    position={Position.Right}
                    type="source"
                />
            </div>
        </div>
    );
}
