import {ITextImportLogic} from "./ITextImportLogic";
import {ImportContext} from "./ImportContext";
import {IXmlImportLogic} from "./IXmlImportLogic";
import {archimateXmlImportLogic} from "../../archimate/import/services/ArchimateXmlImportLogic";

const xmlImportLogics: { [key: string]: IXmlImportLogic } = {};
xmlImportLogics[archimateXmlImportLogic.namespaceUri] = archimateXmlImportLogic;

class XmlImportLogic implements ITextImportLogic {
    public async TryImportText(context: ImportContext, path: string, text: string): Promise<boolean> {
        const doc = new DOMParser().parseFromString(text, "application/xml") as XMLDocument;
        const namespaceUri = doc.documentElement.namespaceURI;
        if (!namespaceUri) {
            context.error("XML", `Document element has no namespace defined at ${path}`);
            return false;
        }

        const xmlImportLogic = xmlImportLogics[namespaceUri];
        if (!xmlImportLogics[namespaceUri]) {
            context.error("XML", `Unknown XML namespace ${namespaceUri} at ${path}`);
            return false;
        }

        return await xmlImportLogic.TryImportXml(context, path, doc.documentElement);
    }
}

export const xmlImportLogic = new XmlImportLogic();