import {IArchimateItemParseLogic} from "./IArchimateItemParseLogic";
import {ImportContext} from "../../../import/services/ImportContext";
import {Xml} from "../../../lib/util";

const elementStereotypes: { [key: string]: string[] } = {
    // shared elements
    "Location": ["element", "shared", "composite", "location"],
    "Junction": ["junction"],
    "Grouping": ["element", "shared", "composite", "grouping"],

    // Motivation layer
    "Stakeholder": ["element", "motivation", "stakeholder"],
    "Driver": ["element", "motivation", "driver"],
    "Assessment": ["element", "motivation", "assessment"],
    "Goal": ["element", "motivation", "goal"],
    "Outcome": ["element", "motivation", "outcome"],
    "Principle": ["element", "motivation", "principle"],
    "Requirement": ["element", "motivation", "requirement"],
    "Constraint": ["element", "motivation", "constraint"],
    "Meaning": ["element", "motivation", "meaning"],
    "Value": ["element", "motivation", "value"],

    // Strategy layer
    "Resource": ["element", "strategy", "resource"],
    "Capability": ["element", "strategy", "capability"],
    "ValueStream": ["element", "strategy", "value stream"],
    "CourseOfAction": ["element", "strategy", "course of action"],

    // Business layer
    "BusinessActor": ["element", "business", "active structure", "actor"],
    "BusinessRole": ["element", "business", "active structure", "role"],
    "BusinessCollaboration": ["element", "business", "active structure", "collaboration"],
    "BusinessInterface": ["element", "business", "active structure", "interface"],
    "BusinessProcess": ["element", "business", "behavior", "process"],
    "BusinessFunction": ["element", "business", "behavior", "function"],
    "BusinessInteraction": ["element", "business", "behavior", "interaction"],
    "BusinessEvent": ["element", "business", "behavior", "event"],
    "BusinessService": ["element", "business", "behavior", "service"],
    "BusinessObject": ["element", "business", "passive structure", "object"],
    "Contract": ["element", "business", "passive structure", "contract"],
    "Representation": ["element", "business", "passive structure", "representation"],
    "Product": ["element", "business", "composite", "product"],

    // Application layer
    "ApplicationComponent": ["element", "application", "active structure", "component"],
    "ApplicationCollaboration": ["element", "application", "active structure", "collaboration"],
    "ApplicationInterface": ["element", "application", "active structure", "interface"],
    "ApplicationFunction": ["element", "application", "behavior", "function"],
    "ApplicationInteraction": ["element", "application", "behavior", "interaction"],
    "ApplicationProcess": ["element", "application", "behavior", "process"],
    "ApplicationEvent": ["element", "application", "behavior", "event"],
    "ApplicationService": ["element", "application", "behavior", "service"],
    "DataObject": ["element", "application", "passive structure", "object"],

    // Technology layer
    "Node": ["element", "technology", "active structure", "node"],
    "Device": ["element", "technology", "active structure", "device"],
    "SystemSoftware": ["element", "technology", "active structure", "system software"],
    "TechnologyCollaboration": ["element", "technology", "active structure", "collaboration"],
    "TechnologyInterface": ["element", "technology", "active structure", "interface"],
    "Path": ["element", "technology", "active structure", "path"],
    "CommunicationNetwork": ["element", "technology", "active structure", "network"],
    "TechnologyFunction": ["element", "technology", "behavior", "function"],
    "TechnologyProcess": ["element", "technology", "behavior", "process"],
    "TechnologyInteraction": ["element", "technology", "behavior", "interaction"],
    "TechnologyEvent": ["element", "technology", "behavior", "event"],
    "TechnologyService": ["element", "technology", "behavior", "service"],
    "Artifact": ["element", "technology", "passive structure", "artifact"],
    "Equipment": ["element", "technology", "active structure", "physical", "equipment"],
    "Facility": ["element", "technology", "active structure", "physical", "facility"],
    "DistributionNetwork": ["element", "technology", "active structure", "physical", "distribution network"],
    "Material": ["element", "technology", "active structure", "physical", "material"],

    // Implementation and migration layer
    "WorkPackage": ["element", "implementation and migration", "work package"],
    "Deliverable": ["element", "implementation and migration", "deliverable"],
    "ImplementationEvent": ["element", "implementation and migration", "event"],
    "Plateau": ["element", "implementation and migration", "plateau"],
    "Gap": ["element", "implementation and migration", "gap"],
};

interface IElementEntity {
    id: string;
    name: string;
}

class ElementParseLogic implements IArchimateItemParseLogic {
    xmlElementNames: string[] = Object.keys(elementStereotypes);

    async importXmlResource(context: ImportContext, path: string, element: Element) {
        const entity = Xml.createFromElement(element) as IElementEntity;
        const resource = context.getOrCreateResource(path, entity.id);
        resource.elementData = {
            ...resource.elementData,
            id: entity.id,
            name: entity.name,
            stereotypes: {"archimate": elementStereotypes[element.localName]}
        }
    }
}

export const elementParseLogic = new ElementParseLogic();