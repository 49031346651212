import {
    CSSProperties,
    useCallback
} from 'react';

import {
    useStore,
    getStraightPath,
    EdgeProps
} from "reactflow";

import {getEdgeParams} from "./Utils";
import {IModelingDiagramRelationshipView} from "../../../modeling/abstractions";

const styles: {
    [stereotype: string]: {
        markerStart?: string;
        markerEnd?: string;
        style: CSSProperties
    }
} = {
    "access": {
        style: {
            stroke: "black",
            strokeDasharray: "2,2",
            strokeWidth: 1
        }
    },
    "aggregation": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "assignment": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "association": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "composition": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "flow": {
        style: {
            stroke: "black",
            strokeDasharray: "5,2",
            strokeWidth: 1
        }
    },
    "influence": {
        style: {
            stroke: "black",
            strokeDasharray: "5,2",
            strokeWidth: 1
        }
    },
    "realization": {
        style: {
            stroke: "black",
            strokeDasharray: "2,2",
            strokeWidth: 1
        }
    },
    "serve": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "specialization": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    },
    "triggering": {
        style: {
            stroke: "black",
            strokeWidth: 1
        }
    }
}

export function ArchimateEdgeView({id, source, target, data}: EdgeProps) {
    const relationshipView = data as IModelingDiagramRelationshipView;

    const stereotypes = relationshipView.relationship.stereotypes$.value["archimate"].value;

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    if(relationshipView.sourceView$.value?.parentView$.value === relationshipView.targetView$.value) {
        return null;
    }

    if(relationshipView.targetView$.value?.parentView$.value === relationshipView.sourceView$.value) {
        return null;
    }

    if (!sourceNode || !targetNode) {
        return null;
    }

    const style = (() => {
        for (const stereotype of stereotypes) {
            if (styles[stereotype]) {
                return styles[stereotype];
            }
        }
    })();

    const {sx, sy, tx, ty} = getEdgeParams(sourceNode, targetNode);
    const [edgePath] = getStraightPath({
        sourceX: sx,
        sourceY: sy,
        targetX: tx,
        targetY: ty,
    });

    return (
        <path
            id={id}
            className="react-flow__edge-path"
            d={edgePath}
            markerStart={style?.markerStart}
            markerEnd={style?.markerEnd}
            style={style?.style}
        />
    );
}