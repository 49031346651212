import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {useState} from "react";
import {useModelingProjectContext} from "./ModelingProjectProvider";

import {ArchimateElementTreeItem} from "../../archimate/components/tree/ArchimateElementTreeItem";

export default function ModelingProjectTreePartial() {
    const {project, tabs$, activeTab$} = useModelingProjectContext();

    const name = "User Account Management Service Functions";
    const diagram = Object.values(project.allDiagrams$.value).find(element => element.name$.value === name);
    let expandedArray = [project.rootElement.id];
    let selectedArray = [project.rootElement.id];
    if (diagram) {
        const existing = tabs$.value.find(tab => tab.id === diagram.id);
        if (!existing)
            tabs$.next([...tabs$.value, diagram]);
        activeTab$.next(diagram.id);

        selectedArray.push(diagram.id);
        let element = diagram.parent$.value;
        while (element) {
            expandedArray.push(diagram.id);
            element = element.parent$.value;
        }
    }

    console.log(expandedArray);

    const [expanded, setExpanded] = useState(expandedArray);
    const [selected, setSelected] = useState(selectedArray);

    return <TreeView
        key="project-tree"
        aria-label="Project Element Tree"
        defaultCollapseIcon={<ExpandMoreIcon/>}
        defaultExpandIcon={<ChevronRightIcon/>}
        expanded={expanded}
        selected={selected}
        onNodeToggle={(event, nodeIds) => {
            setExpanded(nodeIds);
        }}
        sx={{
            minHeight: '100%',
            maxHeight: '100%',
            maxWidth: '20%',
            overflowY: 'auto',
            flexGrow: 1
        }}
    >
        <ArchimateElementTreeItem element={project.rootElement}/>
    </TreeView>
}