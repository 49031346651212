import * as React from "react";
import {getStereotypesImage} from "../stereotypes";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {TreeItem, treeItemClasses} from "@mui/lab";
import {IModelingProjectItem} from "../../../modeling/abstractions";
import {styled} from "@mui/material/styles";
import {bind} from "../../../lib/react-rxjs-ex";
import {MouseEventHandler} from "react";

type StyledTreeItemProps = {
    item: IModelingProjectItem,
    children?: React.ReactNode,
    onClick?: MouseEventHandler<HTMLLIElement>
};

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
    color: theme.palette.text.secondary,
    [`& .${treeItemClasses.content}`]: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
})) as unknown as typeof TreeItem;

const [useName] = bind((item: IModelingProjectItem) => item.name$);

export const ModelingItemTreeItem = React.forwardRef(function StyledTreeItem(
    props: StyledTreeItemProps,
    ref: React.Ref<HTMLLIElement>
) {
    const {
        item,
        ...other
    } = props;

    const name$ = useName(item);

    let stereotypes = item.stereotypes$.get("archimate")?.value ?? [];

    const labelImage = getStereotypesImage(stereotypes);

    return (
        <StyledTreeItemRoot key={item.id} nodeId={item.id} onClick={props.onClick}
            label={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 0.5,
                        pr: 0,
                    }}
                >
                    <Box color="inherit" sx={{mr: 1}}>
                        {labelImage}
                    </Box>
                    <Typography variant="body2" sx={{fontWeight: 'inherit', flexGrow: 1}}>
                        {name$}
                    </Typography>
                    <Typography variant="caption" color="inherit"></Typography>
                </Box>
            }
            {...other}
            ref={ref}
        />
    );
});