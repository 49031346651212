import {ImportContext} from "../../../import/services/ImportContext";
import {IXmlImportLogic} from "../../../import/services/IXmlImportLogic";
import {IArchimateItemParseLogic} from "./IArchimateItemParseLogic";
import {folderParseLogic} from "./FolderParseLogic";
import {elementParseLogic} from "./ElementParseLogic";
import {relationshipParseLogic} from "./RelationshipParseLogic";
import {diagramParseLogic} from "./DiagramParseLogic";

const logicsArray = [
    folderParseLogic,
    elementParseLogic,
    relationshipParseLogic,
    diagramParseLogic
];

const importElementLogics: { [key: string]: IArchimateItemParseLogic } = {};
for (const logic of logicsArray) {
    for (const elementName of logic.xmlElementNames) {
        importElementLogics[elementName] = logic;
    }
}

class ArchimateXmlImportLogic implements IXmlImportLogic {
    public namespaceUri = "http://www.archimatetool.com/archimate";

    private _suppressErrorsForElementTypes: string[] = [];

    public GetSuppressErrorsForElementTypes() {
        return this._suppressErrorsForElementTypes;
    }

    public async TryImportXml(context: ImportContext, path: string, element: Element): Promise<boolean> {
        let archiMateType = element.localName;
        const importLogic = importElementLogics[archiMateType];
        if (!importLogic) {
            context.error(`ArchiMate ${archiMateType}`, `Unknown ArchiMate element type ${archiMateType} at ${path}`);
            return false;
        }

        try {
            await importLogic.importXmlResource(context, path, element);
            return true;
        } catch (ex) {
            context.error(`ArchiMate ${archiMateType}`, `Error importing ArchiMate element type ${archiMateType} at ${path}: ${ex}`);
            return false;
        }
    }
}

export const archimateXmlImportLogic = new ArchimateXmlImportLogic();