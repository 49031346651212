import {IModelingDiagram} from "../abstractions";
import FlowDiagram from "../../archimate/components/diagram/FlowDiagram";
import Box from "@mui/material/Box";
import ProjectElementBoxPartial from "./ProjectElementBoxPartial";
import {ProjectPredictorPartial} from "./ProjectPredictorPartial";
import {ModelingDiagramProvider} from "./ModelingDiagramProvider";

export function ModelingProjectDiagram({diagram}: { diagram: IModelingDiagram }) {
    return (
        <ModelingDiagramProvider diagram={diagram}>
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden'
            }}>
                <Box sx={{
                    height: '100%',
                    width: '100%',
                    flexGrow: 1,
                    overflow: 'hidden'
                }}>
                    <FlowDiagram/>
                </Box>
                <ProjectPredictorPartial/>
                <ProjectElementBoxPartial/>
            </Box>
        </ModelingDiagramProvider>
    );
}