import {ImportContext} from "./ImportContext";
import {IModelingDiagramData, IModelingElementData, IModelingRelationshipData} from "../../modeling/persistence";

export class ParsedResource {
    public readonly context: ImportContext;
    public readonly children: { [resourceName: string]: ParsedResource } = {};

    public readonly parent?: ParsedResource;
    public readonly resourceName: string;

    private _id?: string;

    public get Id(): string {
        return this._id ?? this.resourceName;
    }

    public set Id(value: string | undefined) {
        this._id = value;
    }

    public elementData?: IModelingElementData;
    public relationshipData?: IModelingRelationshipData;
    public diagramData?: IModelingDiagramData;

    constructor(context: ImportContext, id?: string) {
        if (id && context.parsedResources[id])
            throw new Error(`Cannot create item with id '${id}': item with that id already exists.`);
        else if (!id) {
            id = crypto.randomUUID();
            while (context.parsedResources[id])
                id = crypto.randomUUID();
        }

        this.resourceName = id;

        this.context = context;
        context.parsedResources[id] = this;
    }

    public getOrCreateChild(path: string): ChildParsedResource {
        const result = this.children[path];
        if (result) return result;
        return new ChildParsedResource(this, path);
    }
}

export class ChildParsedResource extends ParsedResource {
    constructor(parent: ParsedResource, id?: string) {
        super(parent.context, id);

        parent.children[this.resourceName] = this;
    }
}
