import {IPropertySubject} from "../rxjs-ex";
import {AddStopArg, bind as reactRxjsBind} from "@react-rxjs/core";
import {StateObservable, SUSPENSE} from "@rx-state/core";

export function bind<A extends unknown[], O>(getProperty: (...args: A) => IPropertySubject<O>): [
    (...args: AddStopArg<A>) => Exclude<O, typeof SUSPENSE>,
    (...args: AddStopArg<A>) => StateObservable<O>
] {
    return reactRxjsBind((...args: A) => getProperty(...args), (...args: A) => getProperty(...args).value);
}
