import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import {useModelingProjectContext} from "./ModelingProjectProvider";
import {IModelingDiagram} from "../abstractions";
import {bind} from "../../lib/react-rxjs-ex";
import {IPropertySubject} from "../../lib/rxjs-ex";
import {TabContext, TabPanel} from "@mui/lab";
import {ModelingProjectDiagram} from "./ModelingProjectDiagram";

const [useTabs] = bind((tabs$: IPropertySubject<IModelingDiagram[]>) => tabs$);
const [useSelectedTab] = bind((selectedTab$: IPropertySubject<string>) => selectedTab$);

export function ModelingProjectMainPartial() {
    const {tabs$, activeTab$} = useModelingProjectContext();
    const tabs = useTabs(tabs$);
    const activeTab = useSelectedTab(activeTab$);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        activeTab$.next(newValue);
    };

    return (
        <Box sx={{width: '100%', typography: 'body1'}}>
            <TabContext value={activeTab}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                }}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs
                            value={activeTab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            aria-label="lab API tabs example">
                            {tabs.map((tab, index) => <Tab key={tab.id} label={tab.name$.value} value={tab.id}/>)}
                        </Tabs>
                    </Box>
                    {tabs.map((tab, index) => <TabPanel sx={{
                        margin: 0,
                        padding: 0,
                        flexGrow: 1,
                    }} value={tab.id}><ModelingProjectDiagram
                        diagram={tab}/></TabPanel>)}
                </Box>
            </TabContext>
        </Box>
    );
}