import {IWorkspaceItem} from "../abstractions";
import {useNavigate} from "react-router-dom";
import {Card, CardActionArea} from "@mui/material";
import {bind} from "../../lib/react-rxjs-ex";

const [useName] = bind((item: IWorkspaceItem) => item.Name$);
const [useUpdateDateTime] = bind((item: IWorkspaceItem) => item.UpdateDateTime$);
const [useAccessDateTime] = bind((item: IWorkspaceItem) => item.AccessDateTime$);

export function WorkspaceItemCard({item}: { item: IWorkspaceItem }) {
    const name = useName(item);
    const createDateTime = item.CreateDateTime;
    const updateDateTime = useUpdateDateTime(item);
    const accessDateTime = useAccessDateTime(item);

    const navigate = useNavigate();
    return (
        <Card key={item.Id} sx={{
            maxWidth: 'lg'
        }}>
            <CardActionArea onClick={() => navigate(item.Id ?? ".")}>
                <h2>{name}</h2>
                <p>Created: {createDateTime.toLocaleString()} | Updated: {updateDateTime.toLocaleString()} | Accessed: {accessDateTime.toLocaleString()}</p>
            </CardActionArea>
        </Card>
    )
}
