import {IArchimateItemParseLogic} from "./IArchimateItemParseLogic";
import {ImportContext} from "../../../import/services/ImportContext";
import {Xml} from "../../../lib/util";
import {hrefToId} from "./HrefToId";

const accessRelationship = "AccessRelationship";
const influenceRelationship = "InfluenceRelationship";

const relationshipStereotypes: { [key: string]: string[] } = {
    [accessRelationship]: ["relationship", "access"],
    "AggregationRelationship": ["relationship", "aggregation"],
    "AssignmentRelationship": ["relationship", "assignment"],
    "AssociationRelationship": ["relationship", "association"],
    "CompositionRelationship": ["relationship", "composition"],
    "FlowRelationship": ["relationship", "flow"],
    [influenceRelationship]: ["relationship", "influence"],
    "RealizationRelationship": ["relationship", "realization"],
    "ServingRelationship": ["relationship", "serve"],
    "SpecializationRelationship": ["relationship", "specialization"],
    "TriggeringRelationship": ["relationship", "triggering"],
};

interface IParsedReference {
    href: string;
}

interface IParsedRelationship {
    id: string;
    name?: string;
    accessType?: string; // For Access relationship default — write, 1 — read, 3 — read/write
    strength?: string; // For Influence relationship
    source: IParsedReference[];
    target: IParsedReference[];
}

class RelationshipParseLogic implements IArchimateItemParseLogic {
    xmlElementNames: string[] = Object.keys(relationshipStereotypes);

    async importXmlResource(context: ImportContext, path: string, element: Element) {
        const entity = Xml.createFromElement(element) as IParsedRelationship;
        const resource = context.getOrCreateResource(path, entity.id);
        resource.relationshipData = {
            ...resource.relationshipData,
            id: entity.id,
            name: entity.name,
            sourceId: hrefToId(entity.source?.[0]?.href),
            targetId: hrefToId(entity.target?.[0]?.href),
            stereotypes: {"archimate": relationshipStereotypes[element.localName]}
        }

        if(!resource.relationshipData.sourceId || !resource.relationshipData.targetId)
            context.warn(path, `Relationship ${entity.id} has no source or target`);

        switch (element.localName) {
            case accessRelationship:
                if (entity.accessType)
                    resource.relationshipData.attributes = {
                        accessType: entity.accessType
                    };
                break;
            case influenceRelationship:
                if (entity.strength)
                    resource.relationshipData.attributes = {
                        strength: entity.strength
                    };
                break;
        }
    }
}

export const relationshipParseLogic = new RelationshipParseLogic();