import {IModelingDiagram} from "../../../modeling/abstractions";
import {useModelingProjectContext} from "../../../modeling/components/ModelingProjectProvider";
import {ModelingItemTreeItem} from "./ModelingItemTreeItem";

export function ArchimateDiagramTreeItem({diagram}: { diagram: IModelingDiagram }) {
    const {tabs$, activeTab$} = useModelingProjectContext();

    const click = () => {
        const existing = tabs$.value.find(tab => tab.id === diagram.id);
        if (!existing)
            tabs$.next([...tabs$.value, diagram]);

        activeTab$.next(diagram.id);
    }

    return <ModelingItemTreeItem item={diagram} onClick={click}/>
}