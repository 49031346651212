import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import MuiAppBar from "@mui/material/AppBar";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import UserAppBarPartial from "../../../user/components/UserAppBarPartial";
import {getWorkspaceMenuItems} from "../../../workspace/components/WorkspaceNavigation";
import {useNavigate} from "react-router-dom";

export default function AppBar() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (path?: string) => {
        setAnchorElNav(null);
        if (path)
            navigate(path);
    };

    const menuItems = getWorkspaceMenuItems();

    return (
        <MuiAppBar position={"static"}
                   sx={{
                       flexGrow: 0,
                   }}>
            <Box>
                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={() => handleCloseNavMenu()}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {menuItems.map(({key, name, path}) => (
                                <MenuItem key={key} onClick={() => handleCloseNavMenu(path)}>
                                    <Typography textAlign="center">{name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {menuItems.map(({key, name, path}) => (
                            <Button key={key} onClick={() => handleCloseNavMenu(path)} sx={{
                                my: 2,
                                backgroundColor: 'white',
                                display: 'block'
                            }}>
                                {name}
                            </Button>
                        ))}
                    </Box>

                    <UserAppBarPartial/>
                </Toolbar>
            </Box>
        </MuiAppBar>
    );
}