import {Tooltip} from "@mui/material";
import UserAvatar from "./UserAvatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import Divider from "@mui/material/Divider";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import {useApi} from "../../shared/services/Api";

export default function UserAppBarPartial() {
    const theme = useTheme();
    const api = useApi();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{flexGrow: 0}}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    <UserAvatar user={api.user}/>
                </IconButton>
            </Tooltip>

            {api.isAuthenticated ?
                (
                    <Menu
                        sx={{mt: '60px'}}
                        id="menu-user-authenticated"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <Box sx={{
                            backgroundColor: theme.palette.grey[200],
                            paddingX: theme.spacing(2),
                            paddingY: theme.spacing(1),
                        }}>
                            <Typography variant="subtitle1" fontWeight="bold">{api.user?.name ?? api.user?.email}</Typography>
                        </Box>
                        <Divider/>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography>Profile</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleCloseUserMenu}>
                            <Typography>Settings</Typography>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={() => navigate('/user/logout')}>
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Menu>
                ) : (
                    <Menu
                        sx={{mt: '60px'}}
                        id="menu-user-anonymous"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        <MenuItem onClick={() => navigate('/user/login')}>
                            <Typography>Login</Typography>
                        </MenuItem>
                    </Menu>
                )}
        </Box>
    );
}