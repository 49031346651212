// tslint:disable
/**
 * Aiaea API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Observable } from 'rxjs';
import type { AjaxResponse } from 'rxjs/ajax';
import { BaseAPI } from '../runtime';
import type { OperationOpts, HttpHeaders } from '../runtime';
import type {
    ProblemDetails,
    QueryArchimateRequestBody,
    QueryArchimateResponse,
} from '../models';

export interface QueryArchimateRequest {
    queryArchimateRequestBody?: QueryArchimateRequestBody;
}

/**
 * no description
 */
export class PredictorApi extends BaseAPI {

    /**
     */
    queryArchimate({ queryArchimateRequestBody }: QueryArchimateRequest): Observable<QueryArchimateResponse>
    queryArchimate({ queryArchimateRequestBody }: QueryArchimateRequest, opts?: OperationOpts): Observable<AjaxResponse<QueryArchimateResponse>>
    queryArchimate({ queryArchimateRequestBody }: QueryArchimateRequest, opts?: OperationOpts): Observable<QueryArchimateResponse | AjaxResponse<QueryArchimateResponse>> {

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<QueryArchimateResponse>({
            url: '/_api/predictor/query-archimate',
            method: 'POST',
            headers,
            body: queryArchimateRequestBody,
        }, opts?.responseOpts);
    };

}
