import {textImportLogics} from "./TextImportLogics";
import {blobImportLogics} from "./BlobImportLogics";
import {Path} from "../../lib/util";
import {ParsedResource} from "./ParsedResource";
import {IModelingElementData} from "../../modeling/persistence";

export class ImportContext {
    public readonly parsedResources: { [id: string]: ParsedResource } = {};
    public readonly rootResource: ParsedResource = new ParsedResource(this);
    public readonly warns: { [code: string]: { message: string, data?: any } [] } = {};
    public readonly errors: { [code: string]: { message: string, data?: any } [] } = {};
    public readonly importRootResources: ParsedResource[] = [];

    public addImportRootResource(item: ParsedResource) {
        this.importRootResources.push(item);
    }

    get hasErrors(): boolean {
        return Object.keys(this.errors).length > 0;
    }

    public async tryImportFiles(files: File[]) {
        for (const file of files)
            await this.tryImportFile(file);

        for (const rootResource of this.importRootResources)
            this.linkChildrenToData(rootResource);
    }

    public async tryImportFile(file: File) {
        await this.tryImport(file.name, () => file.text(), () => Promise.resolve(file));
    }

    public async tryImport(path: string, textLoader: () => Promise<string> | undefined, blobLoader: () => Promise<Blob> | undefined): Promise<boolean> {
        const extension = Path.getExtension(path);
        if (!extension) {
            console.warn(`Skipped unknown file type ${path}.`);
            return false;
        }

        const textImporter = textImportLogics[extension];
        if (textImporter) {
            const text = await textLoader();
            if (!text) {
                console.error(`Error importing text file ${path}: no content`);
                return false;
            }

            try {
                return await textImporter.TryImportText(this, path, text);
            } catch (ex) {
                this.error("TEXT", `Error importing text file ${path}: ${ex}`, text)
                return false;
            }
        }

        const blobImporter = blobImportLogics[extension];
        if (blobImporter) {
            const blob = await blobLoader();
            if (!blob) {
                console.error(`Error importing binary file ${path}: no content`);
                return false;
            }

            try {
                await blobImporter.TryImportBlob(this, path, blob);
                return true;
            } catch (ex) {
                console.error(`Error importing binary file ${path}`, ex, blob);
                return false;
            }
        }

        console.warn(`No import logic found for file ${path}.`);
        return false;
    }

    getOrCreateResource(path: string, id?: string): ParsedResource {
        if (id) {
            const existing = this.parsedResources[id];
            if (existing)
                return existing;
        }
        const parts = Path.split(path);
        let result = this.rootResource;
        for (const part of parts)
            result = result.getOrCreateChild(part);
        result.Id = id;
        return result;
    }

    public error(code: string, message: string, data?: any): void {
        if (!this.errors[code])
            this.errors[code] = [{message, data}];
        else if (this.errors[code].every(existing => existing.message === message))
            this.errors[code].push({message, data});
    }

    public warn(code: string, message: string, data?: any): void {
        if (!this.warns[code])
            this.warns[code] = [{message, data}];
        else if (this.warns[code].every(existing => existing.message === message))
            this.warns[code].push({message, data});
    }

    private linkChildrenToData(resource: ParsedResource, parent?: IModelingElementData) {
        if (resource.elementData)
            parent = resource.elementData;
        if (!parent)
            return;

        for (const reourceName in resource.children) {
            const childResource = resource.children[reourceName];
            this.linkChildToElement(parent, childResource);
        }
    }

    private linkChildToElement(parent: IModelingElementData, child: ParsedResource) {
        if (child.elementData && child.elementData.id) {
            parent.elements = {
                ...parent.elements,
                [child.elementData.id]: child.elementData
            };
        }

        if (child.relationshipData && child.relationshipData.id) {
            parent.relationships = {
                ...parent.relationships,
                [child.relationshipData.id]: child.relationshipData
            };
        }

        if (child.diagramData && child.diagramData.id) {
            parent.diagrams = {
                ...parent.diagrams,
                [child.diagramData.id]: child.diagramData
            };
        }

        this.linkChildrenToData(child, parent);
    }
}