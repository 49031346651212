import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

export default function Copyright({org, url, startYear}: {
    org: string,
    url: string,
    startYear?: number
}) {
    const currentYear = new Date().getFullYear();
    const year = startYear && (currentYear > startYear) ? ` ${startYear}—${currentYear}.` : ` ${currentYear}.`;

    return (
        <Typography variant="body2" color="text.secondary">
            {'Copyright © '}
            <Link color="inherit" href={url} target="_blank">{org}</Link>
            {year}
        </Typography>
    );
}
