import {createContext, ReactNode, useContext} from "react";
import {
    IModelingDiagram, IModelingDiagramElementView, IModelingDiagramItemView, IModelingDiagramRelationshipView,
    IModelingElement,
    IModelingProject,
} from "../abstractions";
import {ICollectionSubject, IDictionarySubject, IPropertySubject, PropertySubject} from "../../lib/rxjs-ex";
import {
    IModelingDiagramData, IModelingDiagramElementViewData, IModelingDiagramRelationshipViewData,
} from "../persistence";

class defaultModelingDiagram implements IModelingDiagram {
    get attributes$(): IDictionarySubject<string> {
        throw new Error("Not implemented");
    }

    get elementViews$(): IDictionarySubject<IModelingDiagramElementView>{
        throw new Error("Not implemented");
    }

    get id(): string{
        throw new Error("Not implemented");
    }

    get name$(): IPropertySubject<string | undefined>{
        throw new Error("Not implemented");
    }

    get parent$(): IPropertySubject<IModelingElement | undefined>{
        throw new Error("Not implemented");
    }

    get parentId$(): IPropertySubject<string | undefined>{
        throw new Error("Not implemented");
    }

    get project(): IModelingProject{
        throw new Error("Not implemented");
    }

    get relationshipViews$(): IDictionarySubject<IModelingDiagramRelationshipView>{
        throw new Error("Not implemented");
    }

    get stereotypes$(): IDictionarySubject<ICollectionSubject<string>>{
        throw new Error("Not implemented");
    }

    addStereotypes(notation: string, value: string[]): void {
        throw new Error("Not implemented");
    }

    getDiagramItemView(id: string | undefined): IModelingDiagramItemView | undefined {
        throw new Error("Not implemented");
    }

    getOrCreateElementView(data: IModelingDiagramElementViewData): IModelingDiagramElementView {
        throw new Error("Not implemented");
    }

    getOrCreateRelationshipView(data: IModelingDiagramRelationshipViewData): IModelingDiagramRelationshipView {
        throw new Error("Not implemented");
    }

    readFromData(data: IModelingDiagramData): void {
        throw new Error("Not implemented");
    }

    writeToData(data: IModelingDiagramData): void {
        throw new Error("Not implemented");
    }
}

interface IModelingDiagramContext {
    diagram: IModelingDiagram;
}

const defaultValue = {
    diagram: new defaultModelingDiagram()
}

const context = createContext<IModelingDiagramContext>(defaultValue);

export function useModelingDiagramContext() {
    return useContext(context);
}

export function ModelingDiagramProvider({children, diagram}: {
    diagram: IModelingDiagram;
    children: ReactNode;
}) {
    const value = {
        diagram: diagram,
    }
    return <context.Provider value={value}>{children}</context.Provider>;
}
