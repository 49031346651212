import {IWorkspaceItem} from "../../workspace/abstractions";
import {ModellingLocalStorage} from "./ModellingLocalStorage";
import {
    IModelingDiagram,
    IModelingElement,
    IModelingProject,
    IModelingRelationship
} from "../abstractions";
import {ModelingElement} from "./ModelingElement";
import {ModelingRelationship} from "./ModelingRelationship";
import {DictionarySubject} from "../../lib/rxjs-ex";
import {ModelingDiagram} from "./ModelingDiagram";
import {
    IModelingDiagramData,
    IModelingElementData, IModelingProjectContentData,
    IModelingRelationshipData
} from "../persistence";

export class ModelingProject implements IModelingProject {
    readonly rootElement: IModelingElement;

    public readonly allElements$ = new DictionarySubject<IModelingElement>();

    public canGetOrCreateElement(data: IModelingElementData): boolean {
        if (data.id && this.allElements$.has(data.id)) return true;

        return true;
    }

    public getOrCreateElement(data: IModelingElementData): IModelingElement {
        return this.allElements$.get(data.id) ?? new ModelingElement(this, data);
    }

    public readonly allRelationships$ = new DictionarySubject<IModelingRelationship>();

    public getOrCreateRelationship(data: IModelingRelationshipData): IModelingRelationship {
        return this.allRelationships$.get(data.id) ?? new ModelingRelationship(this, data);
    }

    public readonly allDiagrams$ = new DictionarySubject<IModelingDiagram>();

    public getOrCreateDiagram(data: IModelingDiagramData): IModelingDiagram {
        return this.allDiagrams$.get(data.id) ?? new ModelingDiagram(this, data);
    }

    public getProjectItem(id?: string): IModelingElement | IModelingRelationship | IModelingDiagram | undefined {
        if (!id) return undefined;
        return this.allElements$.get(id) ?? this.allRelationships$.get(id) ?? this.allDiagrams$.get(id);
    }

    readFromData(data: IModelingProjectContentData) {
        if (data.root)
            this.rootElement.readFromData(data.root);
    }

    writeToData(data: IModelingProjectContentData) {
        data.root = {};
        this.rootElement.writeToData(data.root);
    }

    public save() {
        const contentData: IModelingProjectContentData = {};
        this.writeToData(contentData);
        ModellingLocalStorage.WriteProjectContent(this.Summary.Id, contentData);
    }

    constructor(private readonly Summary: IWorkspaceItem) {
        this.rootElement = this.getOrCreateElement({
            id: Summary.Id,
            name: Summary.Name$.value
        });
        const contentData = ModellingLocalStorage.ReadProjectContent(this.Summary.Id);
        if (contentData)
            this.readFromData(contentData);
    }
}
