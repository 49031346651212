import md5 from 'md5';
import queryString from "query-string";
import {Avatar} from "@mui/material";

export interface IUser {
    name?: string;
    nickname?: string;
    email?: string;
    picture?: string;
}

function formGravatarSrcTitle(user?: IUser) {

    if (user?.picture)
        return {
            src: user.picture,
            title: user.nickname ?? user.name ?? user.email
        };

    if (!(user?.email))
        return null;

    const size = 70;
    const rating = 'g';
    const variant = 'retro';
    const protocol = '//';
    const domain = 'www.gravatar.com';

    const base = `${protocol}${domain}/avatar/`;

    const query = queryString.stringify({
        s: size,
        r: rating,
        d: variant,
    });

    const formattedEmail = user.email.trim().toLowerCase();
    let hash = md5(formattedEmail, {encoding: "binary"})
    return {
        src: `${base}${hash}?${query}`,
        title: user.nickname ?? user.name ?? user.email
    };
}

export default function UserAvatar({user}: { user?: IUser }) {

    const {src, title} = formGravatarSrcTitle(user) ?? {};

    return (
        <Avatar variant={"square"}
                sx={{
                    width: 70,
                    height: 70,
                }}
                alt={title}
                src={src}
        />
    );
}