import Container from "@mui/material/Container";
import {useWorkspace} from "./WorkspaceProvider";
import WorkspaceImportDropzone from "./WorkspaceImportDropzone";
import {bind} from "@react-rxjs/core";
import {IWorkspace} from "../abstractions";
import {WorkspaceItemCard} from "./WorkspaceItemCard";

const [useProjects] = bind((workspace: IWorkspace) => workspace.ProjectSummaries$, []);

export default function WorkspacePage() {
    const workspace = useWorkspace();
    const projects = useProjects(workspace);

    return (
        <Container>
            <div key="dropzone">
                <WorkspaceImportDropzone />
                <h1>My Projects</h1>
            </div>
            {projects.map((summary) => {
                return (<div key={summary.Id}>
                    <WorkspaceItemCard item={summary}/>
                </div>);
            })}
        </Container>
    );
}