import {IArchimateItemParseLogic} from "./IArchimateItemParseLogic";
import {ImportContext} from "../../../import/services/ImportContext";
import {
    Path,
    Xml
} from "../../../lib/util";

const archimateModel = "ArchimateModel";
const folder = "Folder";

const folderEntityTypes = ["application",
    "business",
    "diagrams",
    "implementation_migration",
    "motivation",
    "relations",
    "strategy",
    "technology"];

interface IFolderEntity {
    id: string;
    name: string;
    version?: string;
    type?: string;
}

class FolderParseLogic implements IArchimateItemParseLogic {
    xmlElementNames: string[] = [archimateModel, folder];

    async importXmlResource(context: ImportContext, path: string, element: Element) {
        const itemPath = Path.getDirectoryName(path);
        if (!itemPath) throw new Error(`Cannot get directory path for model element at ${path}`);

        const entity = Xml.createFromElement(element) as IFolderEntity;
        const resource = context.getOrCreateResource(itemPath, entity.id);
        resource.elementData = {
            id: entity.id,
            name: entity.name,
        }
        if (entity.version)
            resource.elementData.attributes = {
                version: entity.version
            };

        switch (element.localName) {
            case archimateModel:
                resource.elementData.stereotypes = {
                    "archimate": ["folder", "model"]
                }
                context.addImportRootResource(resource);
                break;
            case folder:
                if (entity.type && folderEntityTypes.indexOf(entity.type) >= 0) {
                    resource.elementData.stereotypes = {
                        "archimate": ["folder", entity.type]
                    }
                }
                break;
        }
    }
}

export const folderParseLogic = new FolderParseLogic();