import ActorIcon from '../img/elements/ActorIcon.svg';
import ArtifactIcon from '../img/elements/ArtifactIcon.svg';
import AssessmentIcon from '../img/elements/AssessmentIcon.svg';
import CapabilityIcon from '../img/elements/CapabilityIcon.svg';
import CollaborationIcon from '../img/elements/CollaborationIcon.svg';
import CommunicationNetworkIcon from '../img/elements/CommunicationNetworkIcon.svg';
import ComponentIcon from '../img/elements/ComponentIcon.svg';
import ConstraintIcon from '../img/elements/ConstraintIcon.svg';
import ContractIcon from '../img/elements/ContractIcon.svg';
import CourseOfActionIcon from '../img/elements/CourseOfActionIcon.svg';
import DeliverableIcon from '../img/elements/DeliverableIcon.svg';
import DeviceIcon from '../img/elements/DeviceIcon.svg';
import DiagramIcon from '../img/elements/DiagramIcon.svg';
import DistributionNetworkIcon from '../img/elements/DistributionNetworkIcon.svg';
import DriverIcon from '../img/elements/DriverIcon.svg';
import EquipmentIcon from '../img/elements/EquipmentIcon.svg';
import EventIcon from '../img/elements/EventIcon.svg';
import FacilityIcon from '../img/elements/FacilityIcon.svg';
import FolderIcon from '../img/elements/FolderIcon.svg';
import FunctionIcon from '../img/elements/FunctionIcon.svg';
import GapIcon from '../img/elements/GapIcon.svg';
import GoalIcon from '../img/elements/GoalIcon.svg';
import GroupIcon from '../img/elements/GroupIcon.svg';
import InteractionIcon from '../img/elements/InteractionIcon.svg';
import InterfaceIcon from '../img/elements/InterfaceIcon.svg';
import JunctionIcon from '../img/elements/JunctionIcon.svg';
import LocationIcon from '../img/elements/LocationIcon.svg';
import MaterialIcon from '../img/elements/MaterialIcon.svg';
import MeaningIcon from '../img/elements/MeaningIcon.svg';
import NodeIcon from '../img/elements/NodeIcon.svg';
import ObjectIcon from '../img/elements/ObjectIcon.svg';
import OutcomeIcon from '../img/elements/OutcomeIcon.svg';
import PathIcon from '../img/elements/PathIcon.svg';
import PlateauIcon from '../img/elements/PlateauIcon.svg';
import PrincipleIcon from '../img/elements/PrincipleIcon.svg';
import ProcessIcon from '../img/elements/ProcessIcon.svg';
import ProductIcon from '../img/elements/ProductIcon.svg';
import RepresentationIcon from '../img/elements/RepresentationIcon.svg';
import RequirementIcon from '../img/elements/RequirementIcon.svg';
import ResourceIcon from '../img/elements/ResourceIcon.svg';
import RoleIcon from '../img/elements/RoleIcon.svg';
import ServiceIcon from '../img/elements/ServiceIcon.svg';
import StakeholderIcon from '../img/elements/StakeholderIcon.svg';
import SystemSoftwareIcon from '../img/elements/SystemSoftwareIcon.svg';
import ValueIcon from '../img/elements/ValueIcon.svg';
import ValueStreamIcon from '../img/elements/ValueStreamIcon.svg';
import WorkPackageIcon from '../img/elements/WorkPackageIcon.svg';
import * as React from "react";

const stereotypeImages: { [stereotype: string]: string } = {
    "actor": ActorIcon,
    "artifact": ArtifactIcon,
    "assesment": AssessmentIcon,
    "capability": CapabilityIcon,
    "collaboration": CollaborationIcon,
    "communicationNetwork": CommunicationNetworkIcon,
    "component": ComponentIcon,
    "constraint": ConstraintIcon,
    "contract": ContractIcon,
    "courseOfAction": CourseOfActionIcon,
    "deliverable": DeliverableIcon,
    "device": DeviceIcon,
    "diagram": DiagramIcon,
    "distributionNetwork": DistributionNetworkIcon,
    "driver": DriverIcon,
    "equipment": EquipmentIcon,
    "event": EventIcon,
    "facility": FacilityIcon,
    "function": FunctionIcon,
    "gap": GapIcon,
    "goal": GoalIcon,
    "group": GroupIcon,
    "interaction": InteractionIcon,
    "interface": InterfaceIcon,
    "junction": JunctionIcon,
    "location": LocationIcon,
    "material": MaterialIcon,
    "meaning": MeaningIcon,
    "node": NodeIcon,
    "object": ObjectIcon,
    "outcome": OutcomeIcon,
    "path": PathIcon,
    "plateau": PlateauIcon,
    "principle": PrincipleIcon,
    "process": ProcessIcon,
    "product": ProductIcon,
    "representation": RepresentationIcon,
    "requirement": RequirementIcon,
    "resource": ResourceIcon,
    "role": RoleIcon,
    "service": ServiceIcon,
    "stakeholder": StakeholderIcon,
    "systemSoftware": SystemSoftwareIcon,
    "value": ValueIcon,
    "valueStream": ValueStreamIcon,
    "workPackage": WorkPackageIcon
};

const stereotypeColors: { [stereotype: string]: string } = {
    motivation: "#ccccff",
    strategy: "#f5deaa",
    business: "#ffffaf",
    application: "#afffff",
    technology: "#afffaf",
    'implementation and migration': "#ffe0e0",
    location: "#eacde0",
};


export function getStereotypesColor(stereotypes: string[]) {
    for (const stereotype of stereotypes) {
        const color = stereotypeColors[stereotype];
        if (color) {
            return color;
        }
    }

    return "rgba(0,0,0,0)";
}

export function getStereotypesImageUrl(stereotypes: string[]) {
    for (const stereotype of stereotypes) {
        const image = stereotypeImages[stereotype];
        if (image) {
            return image;
        }
    }

    return FolderIcon;
}

export function getStereotypesImage(stereotypes: string[]) {
    const color = getStereotypesColor(stereotypes);
    const image = getStereotypesImageUrl(stereotypes);
    return <img style={{
        backgroundColor: color,
        height: '20px',
        width: '20px'
    }} src={image} alt="image"/>;
}